import {formatFileSize} from '@/_custom/helpers'

const FileItem = ({file, removeFile}) => {
  const itemClassName =
    file.progress.percentage >= 100 ? 'dropzone-item-success dropzone-item' : 'dropzone-item'

  return (
    <div className={itemClassName} style={{}} id=''>
      <div className='dropzone-file'>
        <div className='dropzone-filename' title='some_image_file_name.jpg'>
          <span className='me-1'>{file.name}</span>
          <strong>({formatFileSize(file.size)})</strong>
        </div>
        <div className='dropzone-error' data-dz-errormessage=''>
          {file.error}
        </div>
      </div>
      {!file.progress.uploadComplete && (
        <div className='dropzone-progress'>
          <div className='progress'>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{opacity: 1, width: `${file.progress.percentage}%`}}
            ></div>
          </div>
        </div>
      )}
      <div className='dropzone-toolbar'>
        {file.progress.uploadComplete ? (
          <>
            <span className='dropzone-success'>
              <i className='bi bi-check-circle fs-3' />
            </span>

            <span className='dropzone-delete' data-dz-remove='' onClick={() => removeFile(file)}>
              <i className='bi bi-trash fs-3' />
            </span>
          </>
        ) : (
          <span className='dropzone-cancel' data-dz-remove='' onClick={() => removeFile(file)}>
            <i className='bi bi-x fs-1' />
          </span>
        )}
      </div>
    </div>
  )
}

export default FileItem
