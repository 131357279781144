import {z} from 'zod'
import {Response} from './models'
import {validationRules} from './validationRules'

export const fileSchema = z.object({
  filename: z.string(),
  filenameSaved: z.string(),
  fileURL: z.string().url(),
  fileSizeInBytes: z.number().int(),
  fileSize: z.string(),
})

export type IFile = z.infer<typeof fileSchema>

export const getUploadUrlSchema = z
  .object({
    filename: z.string().nonempty(),
    contentType: z.string().nonempty(),
  })
  .strict()

export type IGetUploadUrlForm = z.infer<typeof getUploadUrlSchema>
export interface IUploadUrl {
  key: string
  filename: string
  method: string
  url: string
  fields: object
}

export type IUploadUrlResponse = Response<IUploadUrl>

export const getDownloadUrlSchema = z.object({
  filename: z.string().nonempty(),
  filenameSaved: z.string().nonempty(),
  id: validationRules.objectId, // This id here is not file id but parent case id
})

export type IDownloadUrlForm = z.infer<typeof getDownloadUrlSchema>

export interface IDownloadUrl {
  fileURL: string
}

export type IDownloadUrlResponse = Response<IDownloadUrl>
