import {z} from 'zod'
import {validationRules} from '../validationRules'
import {Team} from '@prisma/models'

const customRuleSchema = z.object({
  id: validationRules.objectIdOptional,
  name: validationRules.safeString,
  users: validationRules.reactSelectID,
})

export const manageOrganizationSchema = z
  .object({
    organization: z.array(customRuleSchema),
  })
  .refine(
    (data) => {
      const names = new Set<string>()
      for (const rule of data.organization) {
        if (names.has(rule.name)) {
          return false
        }
        names.add(rule.name)
      }
      return true
    },
    {
      message: 'Kindly enter unique values in Branch Office/Location',
      path: ['organization'],
    }
  )

export type IManageOrganizationForm = z.infer<typeof manageOrganizationSchema>
export type IOrganization = Team
