import {ResponsivePie} from '@nivo/pie'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {darkTheme} from './nivoTheme' //

const prepareDataArray = (results, field, suffix) => {
  return results.map((result) => ({
    id: result[field],
    label: `${result[field]} ${suffix}`, // Assuming the field represents the status
    value: result._count || 0, // Assuming _count is present in the result
  }))
}

const CardsPie = ({className, dbData, field, suffix, colorScheme = 'set2'}) => {
  const {mode} = useThemeMode()

  const data = prepareDataArray(dbData, field, suffix)

  // FIXME Font doesnt match theme
  return (
    <div className={`card card-flush ${className}`} style={{minHeight: '300px'}}>
      <ResponsivePie
        data={data}
        // colors={customColors} import {customColors} from './nivoTheme' 
        margin={{top: 50, right: 100, bottom: 20, left: 100}}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        theme={mode === 'light' ? {} : darkTheme}
        colors={{scheme: colorScheme}}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor={mode === 'light' ? '#333333' : '#545471'}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{from: 'color'}}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        defs={[
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'overdue',
            },
            id: 'lines',
          },
        ]}
        legends={[
          {
            anchor: 'top-left',
            direction: 'column',
            translateX: -70,
            translateY: -40,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: '#999',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#009ef7',
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}
export {CardsPie}
