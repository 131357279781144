import * as z from 'zod'

const objectIdSchema = z
  .string()
  .trim()
  .length(24)
  .regex(/^[0-9a-fA-F]{24}$/, 'Invalid ID')

const reactSelectIDSchema = z.array(
  z.object({
    value: objectIdSchema,
    label: z.string().nonempty(),
  })
)

const validationRules = {
  objectId: objectIdSchema,
  objectIdOptional: objectIdSchema.optional().nullable(),
  safeString: z
    .string()
    .trim()
    .nonempty()
    .min(3)
    .max(50)
    .regex(/^[\w.\-#&\s]+$/, 'Invalid input. Use letters, numbers, periods, -, #, &, and spaces.'),
  safeTextarea: z.string().trim().nonempty().min(15).max(5000),
  safeTextareaOptional: z.string().trim().max(5000).optional(),
  amountOptional: z.coerce.number().optional().nullable(),
  amount: z.number(),
  dateOptional: z.coerce.date().optional().nullable(),
  date: z.coerce.date(),
  email: z.string().email().nonempty(),
  emailOptional: z.string().email().optional().or(z.literal('')),
  reactSelect: z
    .array(
      z.object({
        value: z.string().nonempty(),
        label: z.string().nonempty(),
      })
    )
    .min(1, {message: 'This is a required field'}),
  reactSelectID: reactSelectIDSchema.min(1, {message: 'This is a required field'}),
  reactSelectIDOptional: reactSelectIDSchema.optional().nullable(),
  gstin: z
    .string()
    .nonempty()
    .length(15)
    .toUpperCase()
    .regex(
      /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[0-9A-Z]{1}$/,
      'Invalid GSTIN Number'
    ),
  pan: z
    .string()
    .nonempty()
    .length(10)
    .regex(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN Number')
    .toUpperCase(),
  password: z
    .string()
    .min(6)
    .regex(
      /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[,!@#$%^&*])/,
      'Password must a mix of letters, numbers, and symbols.'
    ),
}

const enumError = {
  errorMap: () => {
    return {message: 'Please select a valid value'}
  },
}

export {validationRules, enumError}
