import {IUser, SelectList} from '../schemas'

export const renderOptionLabelTeams = (option: any) => {
  const {label, users} = option

  if (users) {
    const userDetails = users.map((user: IUser) => user.email).join(', ')
    const userCount = users.length

    return (
      <div>
        <strong>{`${label} (${userCount})`}</strong>
        <div>{userDetails}</div>
      </div>
    )
  }

  return label
}

export function mapIdsToSelectList(rawIds: string[], options: SelectList[]): SelectList[] {
  return rawIds.reduce<SelectList[]>((tmpArray, rawId) => {
    const row = options.find((row) => row.value === rawId)
    if (row) {
      tmpArray.push({
        value: row.value,
        label: row.label,
      })
    }
    return tmpArray
  }, [])
}

export function convertIdsToSelectList(ids: string[], selectListData: SelectList[] | undefined) {
  if (!ids || !selectListData) return undefined

  const dataSet = new Set(selectListData?.map((option) => option.value))

  const selectList = ids.map((id: string) => {
    const option = dataSet.has(id)
      ? selectListData?.find(({value}) => value === id)
      : {value: id, label: id}
    return option
  })
  return selectList
}


export function apiResponseToReactForm<T>(
  schema: {shape: Record<any, any>},
  apiResponse: Record<any, any>,
  returnNull: boolean = false,
  resetValues: Record<any, any> = {}
): T {
  const data = schema.shape

  const defaultValues: T = {} as T

  for (const key in data) {
    if (apiResponse.hasOwnProperty(key)) {
      let value = apiResponse[key]
      if (
        typeof value === 'string' &&
        value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)
      ) {
        const date = new Date(value)
        value = date.toISOString().substring(0, 10)
      }

      defaultValues[key as keyof T] = value
    } else if (returnNull) {
      let value: any = resetValues.hasOwnProperty(key) ? resetValues[key] : null
      defaultValues[key as keyof T] = value
    }
  }
  return defaultValues
}
