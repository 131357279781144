import React from 'react'

type Props = {
  isSubmitting: boolean
  label?: string
  className?: string
  type?: 'submit' | 'button' | 'reset' | undefined
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function Submit({
  isSubmitting,
  label = 'Submit',
  className = 'btn btn-primary',
  type = 'submit',
  onClick
}: Props) {
  return (
    <button type={type} className={className} disabled={isSubmitting} onClick={onClick}>
      {!isSubmitting ? (
        label
      ) : (
        <span
          className='indicator-progress'
          style={{
            display: 'block',
          }}
        >
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}
