/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import clsx from 'clsx'
import {KTSVG} from '@/_metronic/helpers'
import {useLayout} from '@/_metronic/layout/core'
import {Link} from 'react-router-dom'
import CaseFilterDropdown from './CaseFilterDropdown'
import {useAuth} from '@/app/modules/auth'
import {rbac, Rule} from '@/_custom/schemas'
import CasesDownload from './CasesDownload'

const ToolbarCases = () => {
  const [filterShow, setFilterShow] = useState(false)

  const handleFilterClose = () => setFilterShow(false)
  const handleFilterShow = () => setFilterShow(true)

  const {config} = useLayout()
  const {currentUser} = useAuth()
  const daterangepickerButtonClass = config.app?.toolbar?.fixed?.desktop
    ? 'btn-light'
    : 'bg-body btn-color-gray-700 btn-active-color-primary'

  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      {config.app?.toolbar?.filterButton && (
        <>
          <div className='m-0'>
            <button
              type='button'
              className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}
              onClick={handleFilterShow}
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-6 svg-icon-muted me-1'
              />
              Filter Case
            </button>
            <CaseFilterDropdown handleFilterClose={handleFilterClose} filterShow={filterShow} />
          </div>

          <div className='m-0'>
            <CasesDownload className={clsx('btn btn-sm btn-flex fw-bold', daterangepickerButtonClass)}/>
          </div>
        </>
      )}

      {config.app?.toolbar?.daterangepickerButton && (
        <div
          data-kt-daterangepicker='true'
          data-kt-daterangepicker-opens='left'
          className={clsx(
            'btn btn-sm fw-bold  d-flex align-items-center px-4',
            daterangepickerButtonClass
          )}
        >
          <div className='text-gray-600 fw-bold'>Loading date range...</div>
          <KTSVG path='/media/icons/duotune/general/gen014.svg' className='svg-icon-1 ms-2 me-0' />
        </div>
      )}

      {config.app?.toolbar?.secondaryButton && (
        <a href='#' className='btn btn-sm btn-flex btn-light fw-bold'>
          Filter
        </a>
      )}

      {rbac.can(currentUser?.role, Rule.CASE_CREATE) && (
        <Link to='/cases/new' className='btn btn-sm fw-bold btn-primary'>
          Create
        </Link>
      )}
    </div>
  )
}

export {ToolbarCases}
