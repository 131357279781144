import {z} from 'zod'
import {validationRules} from './validationRules'
import {CaseNote} from '@prisma/models'

export const caseNoteSchema = z.object({
  caseId: validationRules.objectId,
  note: validationRules.safeTextareaOptional,
})

export type ICaseNoteForm = z.infer<typeof caseNoteSchema>
export type ICaseNote = CaseNote
