import {z} from 'zod'
import {enumError, validationRules} from './validationRules'
import {CaseStageStatusKeys, CaseStatusKeys} from './enums'
import {jurisdictionDetailsSchema} from './caseStageSchema'

export const caseImportSchema = z.object({
  entity_gstin: validationRules.gstin,
  case_description: validationRules.safeTextarea,
  stage: z.nativeEnum(CaseStageStatusKeys, enumError),
  nature_of_dispute: validationRules.safeTextareaOptional,
  tax_in_dispute: validationRules.amountOptional,
  case_reference: z.string().max(100).nonempty(),
  date_of_notice: validationRules.date,
  date_of_receipt: validationRules.date,
  due_date_of_reply: validationRules.date,
  period_from: validationRules.date,
  period_to: validationRules.date,
  current_status: z.nativeEnum(CaseStatusKeys, enumError),
  jurisdiction_details: jurisdictionDetailsSchema,
})

export type ICaseImportSchema = z.infer<typeof caseImportSchema>
