import React, {forwardRef, InputHTMLAttributes, Ref} from 'react'
import {TooltipInfo} from '../ui'
import {FormState, FieldValues} from 'react-hook-form'
import {getNestedProperty} from '../../helpers'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  type?: string
  tooltip?: string
  required?: boolean
  formState: FormState<FieldValues>
  fullwidth?: boolean
  inputClassName?: string
}

const Input = forwardRef(
  (
    {
      name,
      label,
      formState,
      tooltip,
      type = 'text',
      fullwidth = false,
      required = true,
      inputClassName = 'form-control form-control-lg form-control-solid',
      ...rest
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const {errors, isSubmitting} = formState
    const labelClassName = required
      ? 'col-form-label required fw-bold fs-6'
      : 'col-form-label fw-bold fs-6'

    const labelWidth = fullwidth
      ? 'd-flex align-items-center'
      : 'col-lg-4 d-flex align-items-center'
    const inputWidth = fullwidth ? 'fv-row' : 'col-lg-8 fv-row'

    return (
      <>
        <div className={labelWidth}>
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
          {tooltip && <TooltipInfo text={tooltip} />}
        </div>

        <div className={inputWidth}>
          <input
            type={type}
            className={inputClassName}
            id={name}
            name={name}
            placeholder={label}
            disabled={isSubmitting}
            {...rest}
            ref={ref}
          />

          {getNestedProperty(errors, name)?.message ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {getNestedProperty(errors, name)?.message.toString()}
              </div>
            </div>
          ) : null}
        </div>
      </>
    )
  }
)

export default Input
