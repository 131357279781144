import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {IChangePasswordForm, changePasswordSchema} from '@/_custom/schemas'
import {useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {PasswordMeterComponent} from '@/_metronic/assets/ts/components'
import {Input, PasswordMeterBar, Submit, showErrorModal} from '@/_custom/components'
import {toast} from 'react-toastify'
import {getErrorMessage} from '@/_custom/helpers'
import {changePassword} from '../core/_requests'

const ChangePassword = () => {
  const navigate = useNavigate()
  const {userId, token} = useParams()

  const {register, handleSubmit, formState} = useForm<IChangePasswordForm>({
    mode: 'onChange',
    resolver: zodResolver(changePasswordSchema),
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const onSubmit = async (values: IChangePasswordForm) => {
    try {
      await changePassword(userId, token, values)

      toast.success('Password Reset Successfully', {
        autoClose: 10000,
      })

      navigate('/auth/login')
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      showErrorModal({message: errorMsg})
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Change Password</h1>
          {/* end::Title */}

          {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
        </div>
        {/* end::Heading */}
        <div>
          <div className='row mb-4' data-kt-password-meter='true'>
            <div className='mb-3'>
              <Input
                {...register('password')}
                label='Password'
                type='password'
                formState={formState}
                required={false}
                fullwidth={true}
                inputClassName='form-control bg-transparent'
              />
            </div>
            <PasswordMeterBar />
          </div>

          <div className='row mb-4'>
            <Input
              {...register('confirmPassword')}
              label='Confirm Password'
              type='password'
              formState={formState}
              required={false}
              fullwidth={true}
              inputClassName='form-control bg-transparent'
            />
          </div>

          <Submit isSubmitting={formState.isSubmitting} className='btn btn-primary w-100 mt-5' />
        </div>
      </>
    </form>
  )
}

export default ChangePassword
