import {KTIcon} from '@/_metronic/helpers'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

interface TooltipInfoProps {
  children?: React.ReactElement<{children: React.ReactNode}>
  text?: string
}

function TooltipInfo({children, text}: TooltipInfoProps) {
  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {children?.props.children}
      {text}
    </Tooltip>
  )

  return (
    <OverlayTrigger overlay={renderTooltip}>
      <button type='button' className='btn btn-icon btn-white'>
        <KTIcon iconName='information-2' className='fs-2' />
      </button>
    </OverlayTrigger>
  )
}

export default TooltipInfo
