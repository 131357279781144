/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Select, {GroupBase, SingleValue} from 'react-select'
import {Modal, Button} from 'react-bootstrap'
import {CaseStageStatus, CaseStatus} from '@/_custom/schemas/enums'
import {createValueLabelFromEnum} from '@/_custom/helpers'
import {QueryEntitiesForSelect} from '../../core/QueryResponseProvider'
import {SelectList} from '@/_metronic/helpers'
import {Rule, rbac} from '@/_custom/schemas'
import {useAuth} from '@/app/modules/auth'

type Props = {
  handleFilterClose: () => void
  filterShow: boolean
}
const CaseStageStatusArray = createValueLabelFromEnum(CaseStageStatus)
const CaseStatusArray = createValueLabelFromEnum(CaseStatus)

const CaseFilterDropdown = ({handleFilterClose, filterShow}: Props) => {
  const {currentUser} = useAuth()
  const enableFilterByEntities = rbac.can(currentUser.role, Rule.CASE_CREATE)
  const {isInitialLoading: isInitialLoadingEntities, data: dataEntities} =
    QueryEntitiesForSelect(enableFilterByEntities)
  const options = dataEntities?.data

  const navigate = useNavigate()
  const [selectedEntity, setSelectedEntity] = useState<SelectList>()
  const [status, setStatus] = useState('')
  const [stage, setStage] = useState('')

  const handleApply = () => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('entity', selectedEntity?.value || '')
    queryParams.set('current_status', status)
    queryParams.set('stage', stage)
    queryParams.set('page', '1') // Reset page to 1
    // window.location.search = queryParams.toString()

    navigate({
      pathname: '/cases',
      search: `?${queryParams.toString()}`,
    })
    handleFilterClose()
  }

  const handleReset = () => {
    setSelectedEntity(undefined)
    setStatus('')
    setStage('')
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.delete('entity')
    queryParams.delete('current_status')
    queryParams.delete('stage')
    queryParams.set('page', '1') // Reset page to 1
    // window.location.search = queryParams.toString()
    navigate({
      pathname: '/cases',
      search: `?${queryParams.toString()}`,
    })
    handleFilterClose()
  }

  return (
    <Modal show={filterShow} onHide={handleFilterClose} backdrop='static' keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Filter Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='px-7 py-2'>
          {enableFilterByEntities && (
            <div className='mb-10'>
              <label className='form-label fw-bold'>Entity:</label>

              <div>
                <Select
                  options={options as unknown as GroupBase<SelectList>[]}
                  isLoading={isInitialLoadingEntities}
                  value={selectedEntity}
                  onChange={(newValue: SingleValue<SelectList>) => {
                    // Check if newValue is not null before using it
                    if (newValue !== null) {
                      setSelectedEntity(newValue)
                    } else {
                      setSelectedEntity(undefined) // or any other appropriate action
                    }
                  }}
                  className='react-select-styled react-select-solid'
                  classNamePrefix='react-select'
                  // styles={customStyles}
                />
              </div>
            </div>
          )}
          <div className='mb-10'>
            <label className='form-label fw-bold'>Status:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              >
                <option value=''>Select Status...</option>
                {CaseStatusArray.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Stage:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={stage}
                onChange={(event) => setStage(event.target.value)}
              >
                <option value=''>Select Stage...</option>
                {CaseStageStatusArray.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleReset}>
          Reset
        </Button>
        <Button variant='primary' onClick={handleApply}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CaseFilterDropdown
