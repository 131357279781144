/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {KTIcon, Response} from '@/_metronic/helpers'

interface ActionCellProps {
  id: string
  deleteItem?: {
    mutateAsync: () => Promise<Response<undefined>>
  }
}

export function ActionCell({id, deleteItem}: ActionCellProps) {
  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`edit/${id}`} className='menu-link px-3'>
            Edit
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {deleteItem && (
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={async () => await deleteItem.mutateAsync()}
            >
              Delete
            </a>
          </div>
        )}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}
