import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '@/_metronic/helpers'
import {CasesQueryResponse, ICase, ICaseUpdate} from '@/_custom/schemas/caseSchema'
import {ICaseStage} from '@/_custom/schemas/caseStageSchema'
import {
  ICaseSubStage,
  ICaseSubStageForm,
  ICaseSubStageUpdate,
} from '@/_custom/schemas/caseSubStageSchema'
import {ICaseImportSchema, ICaseTeamForm, TeamsQueryResponse} from '@/_custom/schemas'
import {ICaseNote, ICaseNoteForm} from '@/_custom/schemas/caseNoteSchema'

const API_URL = process.env.REACT_APP_API_URL
const CASE_URL = `${API_URL}/cases`
const CASE_STAGE_URL = `${API_URL}/case-stage`
const CASE_SUB_STAGE_URL = `${API_URL}/case-sub-stage`
const CASE_TEAM_URL = `${API_URL}/case-team`
const CASE_NOTE_URL = `${API_URL}/case-note`

const getCases = (query: string): Promise<CasesQueryResponse> => {
  return axios.get(`${CASE_URL}?${query}`).then((d: AxiosResponse<CasesQueryResponse>) => d.data)
}

const getCaseById = (id: ID): Promise<ICase | undefined> => {
  return axios
    .get(`${CASE_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

const createCase = (caseRow: ICaseUpdate): Promise<ICase | undefined> => {
  return axios
    .post(CASE_URL, caseRow)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

const updateCase = (caseRow: ICaseUpdate, id: ID): Promise<ICase | undefined> => {
  return axios
    .put(`${CASE_URL}/${id}`, caseRow)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

const deleteCase = (caseId: ID): Promise<void> => {
  return axios.delete(`${CASE_URL}/${caseId}`).then(() => {})
}

const deleteSelectedCases = (caseIds: Array<ID>): Promise<void> => {
  const requests = caseIds.map((id) => axios.delete(`${CASE_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const importCase = (caseRow: ICaseImportSchema): Promise<ICase | undefined> => {
  return axios
    .post(`${CASE_URL}/import`, caseRow)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

//Stages
const getCaseStageById = (id: ID): Promise<ICaseStage | undefined> => {
  return axios
    .get(`${CASE_STAGE_URL}/${id}`)
    .then((response: AxiosResponse<Response<ICaseStage>>) => response.data)
    .then((response: Response<ICaseStage>) => response.data)
}

const createCaseStage = (caseRow: ICaseUpdate): Promise<ICase | undefined> => {
  return axios
    .post(CASE_STAGE_URL, caseRow)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

const updateCaseStage = (caseRow: ICaseUpdate, id: ID): Promise<ICase | undefined> => {
  return axios
    .put(`${CASE_STAGE_URL}/${id}`, caseRow)
    .then((response: AxiosResponse<Response<ICase>>) => response.data)
    .then((response: Response<ICase>) => response.data)
}

const deleteCaseStage = (caseId: ID): Promise<void> => {
  return axios.delete(`${CASE_STAGE_URL}/${caseId}`).then(() => {})
}

//Sub Stages
const createCaseSubStage = (
  caseRow: ICaseSubStageForm | ICaseSubStageUpdate,
  isPreviousStage: boolean
): Promise<ICaseSubStage | undefined> => {
  const query = isPreviousStage ? 'previous_stage=true' : 'previous_stage=false'
  return axios
    .post(`${CASE_SUB_STAGE_URL}?${query}`, caseRow)
    .then((response: AxiosResponse<Response<ICaseSubStage>>) => response.data)
    .then((response: Response<ICaseSubStage>) => response.data)
}

const updateCaseSubStage = (
  caseRow: ICaseSubStageForm | ICaseSubStageUpdate,
  id: ID
): Promise<ICaseSubStage | undefined> => {
  return axios
    .put(`${CASE_SUB_STAGE_URL}/${id}`, caseRow)
    .then((response: AxiosResponse<Response<ICaseSubStage>>) => response.data)
    .then((response: Response<ICaseSubStage>) => response.data)
}

//Teams
const getTeamsByCaseId = (caseId: ID): Promise<TeamsQueryResponse> => {
  return axios
    .get(`${CASE_TEAM_URL}/${caseId}`)
    .then((d: AxiosResponse<TeamsQueryResponse>) => d.data)
}

const assignCaseTeams = (caseTeamData: ICaseTeamForm): Promise<Response<undefined>> => {
  return axios
    .post(CASE_TEAM_URL, caseTeamData)
    .then((response: AxiosResponse<Response<undefined>>) => response.data)
}

//Notes
const getCaseNote = (caseId: ID): Promise<ICaseNote | undefined> => {
  return axios
    .get(`${CASE_NOTE_URL}/${caseId}`)
    .then((response: AxiosResponse<Response<ICaseNote>>) => response.data)
    .then((response: Response<ICaseNote>) => response.data)
}

const createUpdateCaseNote = (postData: ICaseNoteForm): Promise<ICaseNote | undefined> => {
  return axios
    .post(CASE_NOTE_URL, postData)
    .then((response: AxiosResponse<Response<ICaseNote>>) => response.data)
    .then((response: Response<ICaseNote>) => response.data)
}

export {
  getCases,
  deleteCase,
  deleteSelectedCases,
  getCaseById,
  createCase,
  updateCase,
  importCase,
  //Stages
  getCaseStageById,
  createCaseStage,
  updateCaseStage,
  deleteCaseStage,
  //Sub Stages
  createCaseSubStage,
  updateCaseSubStage,
  //Teams
  getTeamsByCaseId,
  assignCaseTeams,
  //Notes
  getCaseNote,
  createUpdateCaseNote,
}
