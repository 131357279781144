import {z} from 'zod'
import {validationRules} from '../validationRules'
import {AdminAutoAssignRules} from '@prisma/models'
import {Response} from '../models'

const customRuleSchema = z.object({
  stateCode: z.string(),
  teams: validationRules.reactSelectID,
})

export const autoAssignRulesSchema = z
  .object({
    custom_rules: z.array(customRuleSchema),
    default_rule: validationRules.reactSelectID,
  })
  .refine(
    (data) => {
      const stateCodes = new Set<string>()
      for (const rule of data.custom_rules) {
        if (stateCodes.has(rule.stateCode)) {
          return false
        }
        stateCodes.add(rule.stateCode)
      }
      return true
    },
    {
      message: 'Kindly Select Unique States',
      path: ['custom_rules'],
    }
  )

export type IAutoAssignRulesSchemaForm = z.infer<typeof autoAssignRulesSchema>
export type IAutoAssignRules = AdminAutoAssignRules

export type AutoAssignRulesQueryResponse = Response<Array<IAutoAssignRules>>
