import {LoadingSpinner, TextVCenter} from '@/_custom/components'
import {Response} from '@/_metronic/helpers'
import {CardsPie} from '@/_metronic/partials/widgets'
import {UseQueryResult} from '@tanstack/react-query'

type Props = {
  queryPieChart: UseQueryResult<Response<any>>
  field: string
  suffix: string
  colorScheme?: string
}

const PieChartLoader = ({queryPieChart, field, suffix, colorScheme}: Props) => {
  return queryPieChart.isLoading ? (
    <LoadingSpinner className='card card-flush card-xl-stretch mb-xl-8 p-1 ' />
  ) : queryPieChart.data?.data ? (
    <CardsPie
      className='card-xl-stretch mb-xl-8'
      dbData={queryPieChart.data?.data}
      field={field}
      suffix={suffix}
      colorScheme={colorScheme}
    />
  ) : (
    <TextVCenter className='card card-xl-stretch mb-xl-8 p-10'>
      No data found. Ensure there are cases created and you have access to data.
    </TextVCenter>
  )
}

export default PieChartLoader
