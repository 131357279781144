import {ReactNode, isValidElement} from 'react'
import {rbac} from '@/_custom/schemas'
import {useAuth} from '@/app/modules/auth'

interface RbacCheckProps {
  rule: string
  children: ReactNode
}

const RbacCheck = ({rule, children}: RbacCheckProps): JSX.Element | null => {
  const {currentUser} = useAuth()

  if (rbac.can(currentUser?.role, rule) && isValidElement(children)) {
    return children
  }

  return null // Return null when the condition is false or children are not JSX elements
}

export {RbacCheck}
