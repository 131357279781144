import React, {forwardRef, TextareaHTMLAttributes, Ref} from 'react'
import {TooltipInfo} from '../ui'
import {FormState, FieldValues} from 'react-hook-form'
import {getNestedProperty} from '../../helpers'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string
  label: string
  tooltip?: string
  required?: boolean
  formState: FormState<FieldValues>
  fullwidth?: boolean
}

const Textarea = forwardRef(
  (
    {name, label, formState, tooltip, required = true, fullwidth = false, ...rest}: Props,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const {errors, isSubmitting} = formState
    const labelClassName = required
      ? 'col-form-label required fw-bold fs-6'
      : 'col-form-label fw-bold fs-6'

    const labelWidth = fullwidth
      ? 'd-flex align-items-center'
      : 'col-lg-4 d-flex align-items-center'
    const inputWidth = fullwidth ? 'fv-row' : 'col-lg-8 fv-row'

    return (
      <>
        <div className={labelWidth}>
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
          {tooltip && <TooltipInfo text={tooltip} />}
        </div>

        <div className={inputWidth}>
          <textarea
            className='form-control form-control-lg form-control-solid'
            id={name}
            name={name}
            defaultValue=''
            placeholder={label}
            disabled={isSubmitting}
            rows={5}
            {...rest}
            ref={ref}
          />

          {getNestedProperty(errors, name)?.message ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {getNestedProperty(errors, name)?.message.toString()}
              </div>
            </div>
          ) : null}
        </div>
      </>
    )
  }
)

export default Textarea
