function calculateItemsOnPage(limit: number, totalResults: number | undefined, pageNum: number) {
  if (!totalResults) {
    return 0
  }

  const maxPageNum = Math.ceil(totalResults / limit)
  if (pageNum < 1 || pageNum > maxPageNum) {
    return 0
  }
  const startIndex = (pageNum - 1) * limit
  return Math.min(limit, totalResults - startIndex)
}

function getErrorMessage(errorObject: any) {
  let errorMsg = null
  if (errorObject?.response?.data?.message) {
    errorMsg = errorObject.response.data.message
  } else if (errorObject?.message) {
    errorMsg = errorObject.message
  }

  return errorMsg
}

function getNestedProperty(obj: any, path: any) {
  const keys = path.split('.')
  let value = obj
  for (const key of keys) {
    if (!value || !value.hasOwnProperty(key)) {
      return undefined
    }
    value = value[key]
  }
  return value
}

interface Option<K extends string | number | symbol = string> {
  value: K
  label: string
}

function createValueLabelFromEnum<E extends Record<string, string | number>>(
  enumObj: E,
  filterKeys?: (keyof E)[]
): Option<keyof E>[] {
  return Object.keys(enumObj)
    .filter((key) => !filterKeys || filterKeys.indexOf(key as keyof E) === -1)
    .map((key) => ({
      value: key as keyof E,
      label: enumObj[key].toString(),
    }))
}

function dateForDisplay(dateString: Date, format = 'short') {
  const date = new Date(dateString)
  let formattedDate
  switch (format) {
    case 'long':
      const dateString = date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      const timeString = date.toLocaleTimeString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      formattedDate = `${dateString}, ${timeString}`
      break
    default:
      formattedDate = date.toLocaleDateString('en-GB')
  }

  return formattedDate
}

function getIsoDate<T>(data: T, propertyName: string): string {
  const value = data[propertyName as keyof T] as any
  if (value) {
    return new Date(value).toISOString().substring(0, 10)
  } else {
    return ''
  }
}

function getEnumValue(enumObj: any, key: string) {
  const value = enumObj[key as keyof typeof enumObj]
  return value !== undefined ? value : key
}

function previewText(text: string | null, maxLength = 300): string | null {
  if (text == null || text.length <= maxLength) {
    return text
  } else {
    const truncated = text.substring(0, maxLength)
    const lastSpaceIndex = truncated.lastIndexOf(' ')
    return lastSpaceIndex === -1
      ? truncated + '...'
      : truncated.substring(0, lastSpaceIndex) + '...'
  }
}

function formatIndianCurrency(num: number): string {
  const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  })
  return formatter.format(num)
}

function parseIndianDate(dateString: string): Date | null {
  // eslint-disable-next-line no-useless-escape
  const dateParts = dateString.split(/[\.\-\/]/)

  if (dateParts.length === 3) {
    const day = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1 // Months are zero-indexed
    const year = parseInt(dateParts[2], 10)

    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
      return new Date(year, month, day)
    }
  }

  return null
}

export {
  calculateItemsOnPage,
  getErrorMessage,
  getNestedProperty,
  createValueLabelFromEnum,
  dateForDisplay,
  getIsoDate,
  getEnumValue,
  previewText,
  formatIndianCurrency,
  parseIndianDate,
}
