import {z} from 'zod'
import {validationRules} from './validationRules'
import {IUser} from './userSchema'

export const loginSchema = z
  .object({
    email: validationRules.email,
    password: validationRules.password,
    token: z.string().optional(),
  })
  .strict()

export const verifyTokenSchema = z
  .object({
    accessToken: z.string().nonempty(),
  })
  .strict()

export const forgotPasswordSchema = z
  .object({
    email: validationRules.email,
  })
  .strict()

export const changePasswordSchema = z
  .object({
    password: validationRules.password,
    confirmPassword: validationRules.password,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

// export const userRegistrationSchema = z
//   .object({
//     name: validationRules.safeString,
//     email: validationRules.email,
//     password: validationRules.password,
//     confirmPassword: validationRules.password,
//   })
//   .refine((data) => data.password === data.confirmPassword, {
//     message: 'Passwords do not match',
//     path: ['confirmPassword'],
//   })

export type ILoginForm = z.infer<typeof loginSchema>
export type IVerifyTokenForm = z.infer<typeof verifyTokenSchema>
export type IForgotPasswordForm = z.infer<typeof forgotPasswordSchema>
export type IChangePasswordForm = z.infer<typeof changePasswordSchema>

export type ITokens = {
  AccessToken: string
  ExpiresIn: number
  TokenType: string
  RefreshToken: string
  IdToken: string
}

export interface IAuthModel {
  user: IUser
  tokens: ITokens
}
