/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect} from 'react'
import {useQuery} from '@tanstack/react-query'
import {
  createResponseContext,
  ID,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  stringifyRequestQuery,
  WithChildren,
} from '@/_metronic/helpers'
import {getCases, getCaseById, getCaseStageById, getTeamsByCaseId, getCaseNote} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import {useNavigate} from 'react-router-dom'
import {caseKeys, caseStageKeys} from '@/_custom/helpers/query_helper'
import {ICase} from '@/_custom/schemas/caseSchema'
import {getEntitiesForSelect} from '@/_custom/core'

const QueryResponseContext = createResponseContext<ICase>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [queryEnabled, setQueryEnabled] = useState(false)
  const updatedQuery = stringifyRequestQuery(state)

  const navigate = useNavigate()

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)

      navigate(
        {
          search: `?${updatedQuery}`,
        },
        {replace: true}
      )
    }

    setQueryEnabled(true)
  }, [updatedQuery, navigate])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery({
    queryKey: caseKeys.list(updatedQuery),
    queryFn: () => {
      return getCases(updatedQuery)
    },
    cacheTime: 300000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 300000,
    enabled: queryEnabled,
  })

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = (id?: ID) => {
  const {response} = useQueryResponse()
  if (!response || !response.data) {
    return []
  }

  if (id) {
    const selectedData = response.data.find((item) => item.id === id)
    return selectedData ? [selectedData] : []
  }

  return response.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.pagination) {
    return defaultPaginationState
  }

  return response.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

const QueryGetById = (id: ID, options = {}) => {
  const queryData = useQuery({
    queryKey: caseKeys.detail(id),
    queryFn: () => {
      return getCaseById(id)
    },
    cacheTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: !!id,
    ...options,
  })

  return queryData
}

const QueryEntitiesForSelect = (enabled = true) => {
  const queryData = useQuery({
    queryKey: caseKeys.detail('EntitiesForSelect'),
    queryFn: () => {
      return getEntitiesForSelect()
    },
    cacheTime: 300000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    enabled,
  })

  return queryData
}

const QueryGetStageById = (id: ID, options = {}) => {
  if (id === 'new') {
    id = null
  }
  const queryData = useQuery({
    queryKey: caseStageKeys.detail(id),
    queryFn: () => {
      return getCaseStageById(id)
    },
    cacheTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    enabled: !!id,
    ...options,
  })

  return queryData
}

const QueryGetTeamsById = (caseId: ID, options = {}) => {
  const queryData = useQuery({
    queryKey: caseStageKeys.detail('teams'),
    queryFn: () => {
      return getTeamsByCaseId(caseId)
    },
    cacheTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    ...options,
    enabled: !!caseId,
  })

  return queryData
}

const QueryGetCaseNote = (caseId: ID, options = {}) => {
  const queryData = useQuery({
    queryKey: caseKeys.detail(caseId + '-note'),
    queryFn: () => {
      return getCaseNote(caseId)
    },
    cacheTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 5000,
    ...options,
    enabled: !!caseId,
  })

  return queryData
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
  QueryGetById,
  QueryEntitiesForSelect,
  QueryGetStageById,
  QueryGetTeamsById,
  QueryGetCaseNote,
}
