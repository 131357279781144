/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'

import {FC} from 'react'
import {PaginationState} from '@/_metronic/helpers'

let maxVisiblePages = 5
let prevText = ''
let nextText = ''
const ellipsis = '...'
if (window.innerWidth >= 768) {
  maxVisiblePages = 10
  prevText = ' Previous'
  nextText = 'Next '
}

type Props = {
  pagination: PaginationState
  isLoading: boolean
  updatePage: (page: number | null) => void
}

const Pagination: FC<Props> = ({pagination, isLoading, updatePage}) => {
  if (!pagination.totalPages) {
    return <></>
  }

  let start = 1
  let end = pagination.totalPages
  if (pagination.totalPages > maxVisiblePages) {
    const left = Math.floor(maxVisiblePages / 2)
    const right = maxVisiblePages - left - 1

    start = pagination.page - left
    end = pagination.page + right

    if (start < 1) {
      end = end + (1 - start)
      start = 1
    }
    if (end > pagination.totalPages) {
      start = start - (end - pagination.totalPages)
      end = pagination.totalPages
    }
  }

  const pages = []
  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_cases_paginate'>
          <ul className='pagination'>
            <li
              className={clsx('page-item', {
                disabled: pagination.page <= 1,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page - 1)}
                style={{cursor: 'pointer'}}
              >
                &laquo; {prevText}
              </a>
            </li>
            {start > 1 && (
              <li key='ellipsis-start' className='page-item'>
                <a className='page-link' onClick={() => updatePage(1)} style={{cursor: 'pointer'}}>
                  {ellipsis}
                </a>
              </li>
            )}
            {pages.map((page) => (
              <li
                key={page}
                className={clsx('page-item', {
                  active: pagination.page === page,
                  disabled: isLoading,
                })}
              >
                <a
                  className='page-link'
                  onClick={() => updatePage(page)}
                  style={{cursor: 'pointer'}}
                >
                  {page}
                </a>
              </li>
            ))}
            {end < pagination.totalPages && (
              <li key='ellipsis-end' className='page-item'>
                <a
                  className='page-link'
                  onClick={() => updatePage(pagination.totalPages || null)}
                  style={{cursor: 'pointer'}}
                >
                  {ellipsis}
                </a>
              </li>
            )}

            <li
              className={clsx('page-item', {
                disabled: pagination.page >= pagination.totalPages,
              })}
            >
              <a
                className='page-link'
                onClick={() => updatePage(pagination.page + 1)}
                style={{cursor: 'pointer'}}
              >
                {nextText} &raquo;
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Pagination}
