/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  QueryCasesCountByStage,
  QueryOpenCloseCount,
  QueryOverdueCases,
  QueryUpcomingCases,
} from './dashboardQuery'
import CasesDashboardTable from './components/CasesDashboardTable'
import PieChartLoader from './components/PieChartLoader'
import {CaseStageStatus} from '@/_custom/schemas'
import {getEnumValue} from '@/_custom/helpers'

const DashboardPage: FC = () => {
  const openCloseCount = QueryOpenCloseCount()
  const casesCountByStage = QueryCasesCountByStage()
  // console.log('casesCountByStage:', casesCountByStage.data)
  const overdueCases = QueryOverdueCases()
  const upcomingCases = QueryUpcomingCases()

  casesCountByStage.data?.data?.forEach((cRow) => {
    cRow.stage = getEnumValue(CaseStageStatus, cRow.stage)
  })

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xl-4'>
          <CardsRadar className='card-xl-stretch mb-xl-8 p-1' />
        </div> */}

        <div className='col-xl-6'>
          <PieChartLoader queryPieChart={openCloseCount} field='current_status' suffix='Cases' colorScheme="category10"  />
        </div>

        <div className='col-xl-6'>
          <PieChartLoader queryPieChart={casesCountByStage} field='stage' suffix='' colorScheme="set2" />
        </div>

        {/* <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} /> */}
        {/* <ListsWidget3 className='card-xxl-stretch mb-xl-8' /> */}
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
      {/* end::Row */}

      <div className='row gy-5 g-xl-8'>
        <CasesDashboardTable queryCases={overdueCases} cardTitle='Overdue Cases' />
        <CasesDashboardTable queryCases={upcomingCases} cardTitle='Upcoming Cases' />
      </div>

      <div className='row gy-5 g-xl-8'>
        <div className='fw-bold text-muted text-center fs-4 font-italic'>
          Note: Above Charts are cached and is refreshed every 10 mins.
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
