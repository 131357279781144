/* eslint-disable react/no-danger-with-children */
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import {Placement} from 'react-bootstrap/esm/types'
import {KTIcon} from '@/_metronic/helpers'

type Props = {
  heading: string
  body: string
  placement?: Placement
}

const TooltipPopover = ({heading, body, placement}: Props) => {
  const popover = (
    <Popover id='popover-basic'>
      <Popover.Header as='h3'>{heading}</Popover.Header>
      <Popover.Body dangerouslySetInnerHTML={{__html: body}} />
    </Popover>
  )

  return (
    <OverlayTrigger trigger='click' placement={placement || 'right'} overlay={popover}>
      <button type='button' className='btn btn-icon btn-white'>
        <KTIcon iconName='information-2' className='fs-2' />
      </button>
    </OverlayTrigger>
  )
}

export default TooltipPopover
