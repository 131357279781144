import {z} from 'zod'
import {validationRules} from './validationRules'
import {Response} from './models'
import {Prisma} from '@prisma/models'
// import {CaseStageKeys, CaseStatusKeys} from './enums'

export const caseSchema = z.object({
  ////////////////**************** DUPLICATE IN caseImportSchema.ts ****************////////////////
  case_description: validationRules.safeTextarea,
  entity_gstin: validationRules.gstin,
  entity: z.object({
    value: validationRules.objectId,
    label: validationRules.safeString,
  }),
})

export const caseUpdateSchema = caseSchema

export type ICase = Prisma.CaseGetPayload<{
  include: {
    caseStages: {
      include: {
        sub_stages: true
      }
    }
  }
}>

export type ICaseUpdate = Omit<Prisma.CaseUpdateInput, 'case_no' | 'caseStages' | 'sub_stages'>
export type ICaseForm = z.infer<typeof caseSchema>

export type CasesQueryResponse = Response<Array<ICase>>
