import React, {forwardRef, HTMLAttributes} from 'react'
import {UseFormRegister, FieldValues, FormState} from 'react-hook-form'
import {getNestedProperty} from '../../helpers'
import {TooltipInfo} from '../ui'

type Option = {
  value: string
  label: string
}

type SelectProps<T extends FieldValues> = {
  label: string
  name: keyof T
  tooltip?: string
  options: Option[]
  formState: FormState<FieldValues>
  fullwidth?: boolean
} & Omit<HTMLAttributes<HTMLSelectElement>, 'name' | 'onChange' | 'onBlur'> &
  ReturnType<UseFormRegister<T>>

type SelectRef = HTMLSelectElement

const Select = forwardRef<SelectRef, SelectProps<FieldValues>>((props, ref) => {
  const {onChange, onBlur, name, label, tooltip, options, formState, fullwidth = false} = props
  const {errors, isSubmitting} = formState

  const labelWidth = fullwidth ? 'd-flex align-items-center' : 'col-lg-4 d-flex align-items-center'
  const inputWidth = fullwidth ? 'fv-row' : 'col-lg-8 fv-row'

  return (
    <>
      <div className={labelWidth}>
        <label htmlFor={name} className='col-form-label required fw-bold fs-6'>
          {label}
        </label>
        {tooltip && <TooltipInfo text={tooltip} />}
      </div>

      <div className={inputWidth}>
        <select
          className='form-select form-select-solid form-select-lg fw-bold'
          id={name}
          disabled={isSubmitting}
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
        >
          <option value=''>Select {label}...</option>
          {options.map(({value, label}) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
        {getNestedProperty(errors, name)?.message ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {getNestedProperty(errors, name)?.message.toString()}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
})

export default Select
