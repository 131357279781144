import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '@/_metronic/helpers'

type Props = {
  header?: string
  text: string
  returnToHome?: boolean
  showIllustration?: boolean
}

const ErrorComponent = ({
  header = 'Oops',
  text,
  returnToHome = true,
  showIllustration = true,
}: Props) => {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-9 text-center'>
          {/* begin::Title */}
          <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{header}</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='fw-semibold fs-6 text-gray-500 mb-7'>{text}</div>
          {/* end::Text */}

          {/* begin::Illustration */}
          {showIllustration && (
            <div className='mb-3'>
              <img
                src={toAbsoluteUrl('/media/auth/404-error.png')}
                className='mw-100 mh-300px theme-light-show'
                alt=''
              />
              <img
                src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
                className='mw-100 mh-300px theme-dark-show'
                alt=''
              />
            </div>
          )}
          {/* end::Illustration */}

          {/* begin::Link */}
          {returnToHome && (
            <div className='mb-0'>
              <Link to='/dashboard' className='btn btn-sm btn-primary'>
                Return Home
              </Link>
            </div>
          )}
          {/* end::Link */}
        </div>
      </div>
    </>
  )
}

export default ErrorComponent
