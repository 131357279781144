import axios, {AxiosResponse} from 'axios'
import {SelectList, SelectListResponse} from '../schemas'
import {
  IDownloadUrlForm,
  IDownloadUrlResponse,
  IGetUploadUrlForm,
  IUploadUrlResponse,
} from '../schemas/fileSchema'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/users`
const ENTITY_URL = `${API_URL}/entities`
const TEAM_URL = `${API_URL}/teams`
const ADMIN_URL = `${API_URL}/admin`
const FILE_URL = `${API_URL}/file`

const getUsersForSelect = (): Promise<SelectListResponse> => {
  return axios.get(`${USER_URL}/select`).then((d: AxiosResponse<SelectListResponse>) => d.data)
}

const getTeamsForSelect = (): Promise<SelectList[] | undefined> => {
  return axios
    .get(`${TEAM_URL}/select`)
    .then((d: AxiosResponse<SelectListResponse>) => d.data)
    .then((d: SelectListResponse) => d.data)
}

const getEntitiesForSelect = (): Promise<SelectListResponse> => {
  return axios.get(`${ENTITY_URL}/select`).then((d: AxiosResponse<SelectListResponse>) => d.data)
}

const getUploadUrl = (postData: IGetUploadUrlForm): Promise<IUploadUrlResponse> => {
  return axios
    .post(`${FILE_URL}/get-upload-url`, postData)
    .then((d: AxiosResponse<IUploadUrlResponse>) => d.data)
}

const getDownloadUrl = (url: string, postData: IDownloadUrlForm): Promise<IDownloadUrlResponse> => {
  return axios
    .post(`${API_URL}/${url}`, postData)
    .then((d: AxiosResponse<IDownloadUrlResponse>) => d.data)
}

export {
  API_URL,
  USER_URL,
  TEAM_URL,
  ENTITY_URL,
  ADMIN_URL,
  getUsersForSelect,
  getTeamsForSelect,
  getEntitiesForSelect,
  getUploadUrl,
  getDownloadUrl,
}
