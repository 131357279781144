import {ID} from '../schemas'

// all      ['entities']
// lists    ['entities', 'list']
// list     ['entities', 'list', {filters}]
// details  ['entities', 'detail']
// detail   ['entities', 'detail', id]

export const entityKeys = {
  all: ['entities'] as const, // ['entities']
  lists: () => [...entityKeys.all, 'list'] as const, // ['entities', 'list']
  list: (filters: string) => [...entityKeys.lists(), {filters}] as const, // ['entities', 'list', {filters}]
  details: () => [...entityKeys.all, 'detail'] as const, // ['entities', 'detail']
  detail: (id: ID) => [...entityKeys.details(), id] as const, // ['entities', 'detail', id]
}

export const teamKeys = {
  all: ['teams'] as const,
  lists: () => [...teamKeys.all, 'list'] as const,
  list: (filters: string) => [...teamKeys.lists(), {filters}] as const,
  details: () => [...teamKeys.all, 'detail'] as const,
  detail: (id: ID) => [...teamKeys.details(), id] as const,
}

export const userKeys = {
  all: ['users'] as const,
  lists: () => [...userKeys.all, 'list'] as const,
  list: (filters: string) => [...userKeys.lists(), {filters}] as const,
  details: () => [...userKeys.all, 'detail'] as const,
  detail: (id: ID) => [...userKeys.details(), id] as const,
}

export const caseKeys = {
  all: ['cases'] as const,
  lists: () => [...caseKeys.all, 'list'] as const,
  list: (filters: string) => [...caseKeys.lists(), {filters}] as const,
  details: () => [...caseKeys.all, 'detail'] as const,
  detail: (id: ID) => [...caseKeys.details(), id] as const,
}

export const caseStageKeys = {
  all: ['caseStages'] as const,
  lists: () => [...caseStageKeys.all, 'list'] as const,
  list: (filters: string) => [...caseStageKeys.lists(), {filters}] as const,
  details: () => [...caseStageKeys.all, 'detail'] as const,
  detail: (id: ID) => [...caseStageKeys.details(), id] as const,
}

export const adminKeys = {
  all: ['admin'] as const,
  lists: () => [...adminKeys.all, 'list'] as const,
  list: (filters: string) => [...adminKeys.lists(), {filters}] as const,
  details: () => [...adminKeys.all, 'detail'] as const,
  detail: (id: ID) => [...adminKeys.details(), id] as const,
}

export const dashboardKeys = {
  all: ['dashboard'] as const,
  lists: () => [...dashboardKeys.all, 'list'] as const,
  list: (filters: string) => [...dashboardKeys.lists(), {filters}] as const,
  details: () => [...dashboardKeys.all, 'detail'] as const,
  detail: (id: ID) => [...dashboardKeys.details(), id] as const,
}
