import React from 'react'
import {createRoot} from 'react-dom/client'
import {ErrorModal, ErrorModalProps} from './ErrorModal'

export const showErrorModal = ({header, message}: ErrorModalProps): Promise<void> => {
  return new Promise((resolve) => {
    const handleClose = () => {
      root.unmount()
      resolve()
    }

    let domNode = document.getElementById('tmpRender')

    if (!domNode) {
      domNode = document.createElement('div')
      domNode.setAttribute('id', 'tmpRender')
    }

    if(message === "Request failed with status code 400") {
      message = "Form Submission Failed. Ensure that you have filed all required fields with valid values"
    }

    const root = createRoot(domNode)
    root.render(<ErrorModal header={header} message={message} show={true} onHide={handleClose} />)
    document.body.appendChild(domNode)
  })
}
