import {Col, Placeholder, Row} from 'react-bootstrap'

const SkeletonRow = () => {
  return (
    <Row>
      <Col xs={12} sm={4}>
        <Placeholder as='p' animation='glow'>
          <Placeholder xs={8} size='lg' />
        </Placeholder>
      </Col>
      <Col xs={12} sm={8}>
        <Placeholder as='p' animation='glow'>
          <Placeholder xs={8} size='lg' />
        </Placeholder>
      </Col>
    </Row>
  )
}

export const SkeletonForm = ({rows = 3, button = true}) => {
  const skeletonRows = [...Array(rows)].map((_, i) => <SkeletonRow key={i} />)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='border-0 p-5'>
        <div className='card-title m-0 px-5'>
          <Placeholder as='h2' animation='glow'>
            <Placeholder xs={4} size='lg' />
          </Placeholder>
        </div>
      </div>

      <div className='card-body border-top p-9'>{skeletonRows}</div>

      {button && (
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <Placeholder.Button xs={2} aria-hidden='true' />
        </div>
      )}
    </div>
  )
}
