import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import {getUploadUrl} from '@/_custom/core'

const UppyInstance = new Uppy({debug: false})

UppyInstance.use(AwsS3, {
  async getUploadParameters(file) {
    const params = {
      filename: file.name,
      contentType: file.type,
    }
    try {
      // GOOD-TO-HAVE We can pass additional meta data like case id and file category to the server
      const res = await getUploadUrl(params)
      const data = res.data

      const returnParams = {
        method: data.method,
        url: data.url,
        fields: data.fields,
        // Provide content type header required by S3
        headers: {
          'Content-Type': file.type,
        },
      }

      return returnParams
    } catch (error) {
      let msg = 'Unable to upload file. Please try again.'
      if (error.response) {
        msg = error.response.data.message
      }
      // TODO show notification
      console.log('msg:', msg)
    }
  },
})

export default UppyInstance
