import {z} from 'zod'
import {validationRules} from './validationRules'
import {Response} from './models'
import {RoleKeys} from './enums'
import {Prisma} from '@prisma/models'

const branchDetailsSchema = z
  .array(
    z.object({
      team_id: validationRules.objectIdOptional,
      branch_name: validationRules.safeString,
      gstin: validationRules.gstin,
      users: validationRules.reactSelectIDOptional,
    })
  )
  .min(1, {message: 'Please Fill at least 1 Branch Detail'})
  .refine(
    (arr) => {
      const names = arr.map((item) => item.branch_name + item.gstin)
      return new Set(names).size === names.length
    },
    {message: 'Branch+GSTIN must be unique'}
  )

const inviteUserSchema = z
  .array(
    z.object({
      email: validationRules.email,
      role: z.enum([RoleKeys.taxpayer_admin, RoleKeys.taxpayer_user]),
    })
  )
  .min(1, {message: 'Please Invite at least 1 User'})
  .refine(
    (inviteUsers) => {
      return inviteUsers.some((user) => user.role === RoleKeys.taxpayer_admin)
    },
    {message: 'At least one user must have the Admin role'}
  )

export const entitiesSchema = z
  .object({
    entity_name: validationRules.safeString,
    entity_pan: validationRules.pan,
    primaryTeamIds: validationRules.reactSelectID,
    branch_details: branchDetailsSchema,
    invite_users: inviteUserSchema,
    team_id: validationRules.objectIdOptional,
  })
  .strict()

// In edit mode, you can use the modified schema without the invite_users field
export const entitiesEditSchema = entitiesSchema //.omit({invite_users: true})

export type IEntity = Prisma.EntityGetPayload<{
  include: {
    teams: true
  }
}>

export type IEntityUpdate = Prisma.EntityUpdateInput
export type IEntityForm = z.infer<typeof entitiesSchema>
export type IEntityEditForm = z.infer<typeof entitiesEditSchema>
export type EntitiesQueryResponse = Response<Array<IEntity>>
