import { useEffect } from 'react'
import {
  ToolbarCases,
  ToolbarClassic
} from './toolbars'

type Props = {
  pageToolBar: string | undefined
}

const Toolbar = ({ pageToolBar }: Props) => {
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-enabled', 'true')
  }, [pageToolBar])

  switch (pageToolBar) {
    case 'classic':
      return <ToolbarClassic />
    case 'casesToolbar':
      return <ToolbarCases />
    default:
      return <></>
  }
}

export { Toolbar }
