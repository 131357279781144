import {z} from 'zod'
import {enumError, validationRules} from './validationRules'
import {Response} from './models'
import {RoleKeys} from './enums'
import {User, Prisma} from '@prisma/models'

export const userSchema = z
  .object({
    id: z.string().optional(),
    name: validationRules.safeString,
    email: validationRules.email,
    role: z.enum(
      [
        RoleKeys.consultant_admin,
        RoleKeys.consultant_user,
        RoleKeys.taxpayer_admin,
        RoleKeys.taxpayer_user,
      ],
      enumError
    ),
  })
  .strict()

export const userRegistrationSchema = z
  .object({
    name: validationRules.safeString,
    email: validationRules.email,
    password: validationRules.password,
    confirmPassword: validationRules.password,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  })

export const userUpdateSchema = userSchema.omit({email: true})

export type IUser = User
export type IUserUpdate = Omit<Prisma.UserUpdateInput, 'email'>
export type UsersQueryResponse = Response<Array<IUser>>

export type IUserRegistrationForm = z.infer<typeof userRegistrationSchema>
