import {z} from 'zod'
import {enumError, validationRules} from './validationRules'
import {Prisma, CaseSubStage} from '@prisma/models'
import {CaseStatusKeys} from './enums'
import {fileSchema} from './fileSchema'

const taxInterestPenaltySchema = z
  .object({
    tax_amount: validationRules.amountOptional,
    interest_amount: validationRules.amountOptional,
    penalty_amount: validationRules.amountOptional,
  })
  .optional()

// const caseStatusKeysWithoutClosed = Object.values(CaseStatusKeys).filter(
//   (status) => status !== CaseStatusKeys.Closed
// )
// console.log('caseStatusKeysWithoutClosed:', caseStatusKeysWithoutClosed)

export const caseSubStageSchemaNormalStages = z.object({
  caseId: validationRules.objectId,
  caseStageId: validationRules.objectId,
  case_reference: z.string().max(100).optional().nullable(),
  sub_stage_status: z.enum(
    [
      CaseStatusKeys.AdditionalPHRequired,
      CaseStatusKeys.AdjournmentFiled,
      CaseStatusKeys.OrderPassed,
      CaseStatusKeys.PHCompleted,
      CaseStatusKeys.PHPending,
      CaseStatusKeys.ResponsePending,
      CaseStatusKeys.ResponseSubmitted,
    ],
    enumError
  ),
  current_status_date: validationRules.date,
  ph_date: validationRules.dateOptional,
  comments: validationRules.safeTextareaOptional,
  files: z.array(fileSchema).optional(),
  original_demand: taxInterestPenaltySchema,
  amount_admitted_and_paid: taxInterestPenaltySchema,
  disputed_amount: validationRules.amountOptional,
  demand_dropped: taxInterestPenaltySchema,
  demand_confirmed: taxInterestPenaltySchema,
  pre_deposit_amount: validationRules.amountOptional,
})

export const closeCaseSchema = z.object({
  caseId: validationRules.objectId,
  caseStageId: validationRules.objectId,
  case_reference: z.string().max(100).optional().nullable(),
  sub_stage_status: z.enum([CaseStatusKeys.Closed], enumError),
  current_status_date: validationRules.date,
  comments: validationRules.safeTextarea,
  files: z.array(fileSchema).optional(),
})

export const caseSubStageSchema = z.discriminatedUnion('sub_stage_status', [
  caseSubStageSchemaNormalStages,
  closeCaseSchema,
])

export type ICaseSubStage = CaseSubStage
export type ICaseSubStageUpdate = Prisma.CaseSubStageUpdateInput
export type ICaseSubStageForm = z.infer<typeof caseSubStageSchema>
