import React from 'react'

interface LoadingButtonProps {
  isLoading: boolean
  label: string
}

const LoadingButton: React.FC<LoadingButtonProps> = ({isLoading, label}) => {
  return (
    <>
      {!isLoading ? (
        <span>{label}</span>
      ) : (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </>
  )
}

export default LoadingButton