/* eslint-disable jsx-a11y/anchor-is-valid */

import {LoadingSpinner, TextVCenter} from '@/_custom/components'
import {dateForDisplay, getEnumValue} from '@/_custom/helpers'
import {CaseStageStatus, ICase} from '@/_custom/schemas'
import {Response} from '@/_metronic/helpers'
import {UseQueryResult} from '@tanstack/react-query'
import {Link} from 'react-router-dom'
import ago from 's-ago'
// var ago = require('s-ago')

type Props = {
  cardTitle: string
  queryCases: UseQueryResult<Response<ICase[]>>
}

const CasesDashboardTable = ({cardTitle, queryCases}: Props) => {
  return (
    <div className='col-xl-6'>
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{cardTitle}</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          {queryCases.isLoading ? (
            <LoadingSpinner />
          ) : !queryCases.data?.data?.length ? (
            <TextVCenter>No {cardTitle}</TextVCenter>
          ) : (
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px'>Case</th>
                    <th className='min-w-120px'>Entity</th>
                    <th className='min-w-100px'>Due Date</th>
                  </tr>
                </thead>
                <tbody>
                  {queryCases.data?.data?.map((cRow: ICase, index) => (
                    <tr key={index}>
                      <td>
                        <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
                          <Link to={`/cases/${cRow.id}`}>{`#LMS-${cRow.case_no}`}</Link>
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {getEnumValue(CaseStageStatus, cRow.stage)}
                        </span>
                      </td>
                      <td>
                        <span className='text-gray-800 fw-bold d-block fs-6'>
                          {cRow.entity_name}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {cRow.entity_gstin}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-semibold d-block fs-7'>
                          {dateForDisplay(cRow.due_date_of_reply)}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {ago(new Date(cRow.due_date_of_reply))}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CasesDashboardTable
