import React, {ReactNode} from 'react'
import {KTIcon} from '@/_metronic/helpers'

interface DropdownProps {
  children: ReactNode
}

const DropdownMenu: React.FC<DropdownProps> = ({children}) => {
  return (
    <div className='dropdown'>
      <button
        className='btn btn-sm btn-icon btn-active-light-primary me-3'
        type='button'
        id='dropdownMenuButton'
        data-bs-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        <KTIcon iconName='dots-square-vertical' className='fs-2x' />
      </button>
      <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
        {children}
      </div>
    </div>
  )
}

export default DropdownMenu
