import React from 'react'

type Props = {
  children?: React.ReactNode
  className?: string
}
const TextVCenter = ({children, className}: Props) => {
  return (
    <div className={`${className} d-flex justify-content-center align-items-center`}>
      {children}
    </div>
  )
}

export default TextVCenter
