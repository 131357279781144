import {IDownloadUrlResponse} from '@/_custom/schemas'
import {useState} from 'react'
import {getCases} from '../../core/_requests'
import {KTSVG} from '@/_metronic/helpers'

type Props = {
  className: string
}

const CasesDownload = ({className}: Props) => {
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    setLoading(true)
    try {
      const queryParams = new URLSearchParams(window.location.search)
      queryParams.set('download', '1') // Reset page to 1
      // window.location.search = queryParams.toString()

      // navigate({
      //   pathname: '/cases',
      //   search: `?${queryParams.toString()}`,
      // })

      const {data} = (await getCases(queryParams.toString())) as unknown as IDownloadUrlResponse
      const presignedURL = data?.fileURL
      const link = document.createElement('a')
      link.href = presignedURL || ''
      link.setAttribute('download', `cases-${Date.now()}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setLoading(false)
    } catch (error) {
      console.error('Error downloading file:', error)
      setLoading(false)
    }
  }

  return (
    <button type='button' className={className} onClick={handleDownload} disabled={loading}>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          <KTSVG
            path='/media/icons/duotune/files/fil017.svg'
            className='svg-icon-6 svg-icon-muted me-1 fs-1'
          />
          Download Cases
        </>
      )}
    </button>
  )
}

export default CasesDownload
