import {zodResolver} from '@hookform/resolvers/zod'
import {useForm} from 'react-hook-form'
import {IUserRegistrationForm, userRegistrationSchema} from '@/_custom/schemas'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useEffect} from 'react'
import {PasswordMeterComponent} from '@/_metronic/assets/ts/components'
import {Input, PasswordMeterBar, Submit, showErrorModal} from '@/_custom/components'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {
  getUserForRegistration,
  registerByInvite,
} from '../../apps/user-management/components/core/_requests'
import {Spinner} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {getErrorMessage} from '@/_custom/helpers'

const InviteRegistration = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const {userId, token} = useParams()
  const {data, isInitialLoading} = useQuery({
    queryKey: ['user', userId],
    queryFn: () => {
      return getUserForRegistration(userId, token)
    },
  })

  const {register, handleSubmit, formState, reset} = useForm<IUserRegistrationForm>({
    mode: 'onChange',
    resolver: zodResolver(userRegistrationSchema),
  })

  useEffect(() => {
    if (data) {
      reset({
        name: data.name as string,
        email: data.email,
      })
    }
  }, [data, reset])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const onSubmit = async (values: IUserRegistrationForm) => {
    try {
      await registerByInvite(userId, token, values)

      toast.success('Account Created Successfully', {
        autoClose: 10000,
      })

      navigate('/auth/login')
      await queryClient.invalidateQueries({queryKey: ['user']})
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      showErrorModal({message: errorMsg})
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isInitialLoading ? (
        <div className='text-center'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <h2 className='text-dark mt-3'>Loading...</h2>
        </div>
      ) : (
        ''
      )}

      {!isInitialLoading && !data ? (
        <>
          <div className='text-center'>
            <h2 className='text-dark fw-bolder mb-3'>Invalid Link</h2>
            <p>
              <div className='text-gray-500 fw-semibold fs-6 mb-5'>
                If you have already registered with the link, then click here to login now.
              </div>

              <div>
                <Link to='/auth/login' className='btn btn-primary'>
                  Login
                </Link>
              </div>
            </p>
          </div>
        </>
      ) : (
        ''
      )}

      {!isInitialLoading && data ? (
        <>
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}

            {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
          </div>
          {/* end::Heading */}
          <div>
            <div className='row mb-4'>
              <Input
                {...register('name')}
                label='Name'
                formState={formState}
                required={false}
                fullwidth={true}
                inputClassName='form-control bg-transparent'
              />
            </div>

            <div className='row mb-4'>
              <Input
                {...register('email')}
                label='Email'
                type='email'
                formState={formState}
                required={false}
                fullwidth={true}
                inputClassName='form-control bg-transparent'
                disabled={true}
              />
            </div>

            <div className='row mb-4' data-kt-password-meter='true'>
              <div className='mb-3'>
                <Input
                  {...register('password')}
                  label='Password'
                  type='password'
                  formState={formState}
                  required={false}
                  fullwidth={true}
                  inputClassName='form-control bg-transparent'
                />
              </div>
              <PasswordMeterBar />
            </div>

            <div className='row mb-4'>
              <Input
                {...register('confirmPassword')}
                label='Confirm Password'
                type='password'
                formState={formState}
                required={false}
                fullwidth={true}
                inputClassName='form-control bg-transparent'
              />
            </div>

            <Submit isSubmitting={formState.isSubmitting} className='btn btn-primary w-100 mt-5' />
          </div>
        </>
      ) : (
        ''
      )}
    </form>
  )
}

export default InviteRegistration
