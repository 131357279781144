import {IAuthModel} from '@/_custom/schemas/authSchema'
import {refreshToken} from './_requests'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): IAuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: IAuthModel = JSON.parse(lsValue) as IAuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: IAuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth?.tokens?.IdToken) {
        config.headers.Authorization = `Bearer ${auth.tokens.IdToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      if (!error.response) {
        return Promise.reject(error)
      }
      const {status, data} = error.response
      const prevRequest = error?.config

      if (status === 401 && data?.message === 'Please authenticate' && !prevRequest?.sent) {
        //KNOWN-ISSUES The above error message is probably different with cognito authorizer
        const auth = getAuth()

        if (auth) {
          try {
            const newTokens = await refreshToken(auth.tokens.RefreshToken)
            const newAuth = {...auth, tokens: newTokens}
            setAuth(newAuth)

            axios.defaults.headers.Authorization = `Bearer ${newTokens.IdToken}`
            const res = await axios.request(error.config)
            return res
          } catch (e) {
            removeAuth()
            return Promise.reject(e)
          }
        }
      }
      // BUG removeAuth(s) is not resulting in redirect to login page as probably state in context is not getting updated
      // react_jwt_auth\src\hooks\useAxiosPrivate.js this can be used to fix it.
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
