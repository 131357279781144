import {Spinner} from 'react-bootstrap'
type Props = {
  className?: string
}

const LoadingSpinner = ({className}: Props) => {
  return (
    <div className={`${className} d-flex justify-content-center align-items-center`}>
      <Spinner animation='border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  )
}

export default LoadingSpinner
