export const Rule = Object.freeze({
  RBAC_DISABLED: 'rbac:*',
  NA: 'na',
  //admin
  ADMIN_ALL: 'admin:*',
  ADMIN_AUTO_ASSIGN_RULES: 'admin:autoAssignRules',
  ADMIN_MANAGE_ORGANIZATION: 'admin:manageOrganization',
  //case
  CASE_ALL: 'case:*',
  CASE_CREATE: 'case:create',
  CASE_READ: 'case:read',
  CASE_UPDATE: 'case:update',
  CASE_DELETE: 'case:delete',
  //case-teams
  CASE_TEAMS_ASSIGN: 'caseTeams:assign',
  //case-note
  CASE_NOTE_CREATE: 'caseNote:create',
  CASE_NOTE_READ: 'caseNote:read',
  //teams
  TEAMS_ALL: 'teams:*',
  TEAMS_CREATE: 'teams:create',
  TEAMS_READ: 'teams:read',
  TEAMS_UPDATE: 'teams:update',
  TEAMS_DELETE: 'teams:delete',
  //users
  USERS_ALL: 'users:*',
  USERS_CREATE: 'users:create',
  USERS_READ: 'users:read',
  USERS_UPDATE: 'users:update',
  USERS_DELETE: 'users:delete',
  //entities
  ENTITIES_ALL: 'entities:*',
  ENTITIES_CREATE: 'entities:create',
  ENTITIES_READ: 'entities:read',
  ENTITIES_UPDATE: 'entities:update',
  ENTITIES_DELETE: 'entities:delete',
})
