import {forwardRef, HTMLAttributes} from 'react'
import {UseFormRegister, FieldValues, FormState, Controller, Control} from 'react-hook-form'
import Select from 'react-select'
import {getNestedProperty} from '../../helpers'
import {TooltipInfo} from '../ui'
import {SelectList} from '@/_metronic/helpers'

type SelectProps<T extends FieldValues> = {
  label: string
  name: keyof T
  tooltip?: string
  options: SelectList[]
  formState: FormState<FieldValues>
  control: Control<T>
  isMulti?: boolean
  fullwidth?: boolean
  required?: boolean
  renderOptionLabel?: (option: any) => string
} & Omit<HTMLAttributes<HTMLSelectElement>, 'name' | 'onChange' | 'onBlur'> &
  ReturnType<UseFormRegister<T>>

type SelectRef = HTMLSelectElement

const customStyles = {
  control: (base: any, {isFocused, isDisabled}: any) => ({
    ...base,
    appearance: 'none',
    boxShadow: 'none !important',
    backgroundColor: '#f9f9f9',
    border: 'none',
    fontSize: '1.15rem',
    fontFamily: 'inherit',
    lineHeight: 1.5,
  }),
  singleValue: (base: any) => ({
    ...base,
    color: '#5e6278',
    padding: '0.5rem',
    fontWeight: 600,
  }),
  multiValue: (base: any) => ({
    ...base,
    color: '#5e6278',
    padding: '0.15rem',
    marginRight: '0.65rem',
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
    backgroundColor: '#e1e3ea',
    fontWeight: 500,
  }),
  option: (base: any, {isSelected}: any) => ({
    ...base,
    color: '#5e6278',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: '#d7d7d7',
      fontWeight: 500,
      color: '#5e6278',
    },
  }),
}

const getOptionValue = (option: any) => option.value

const ReactSelect = forwardRef<SelectRef, SelectProps<FieldValues>>((props, _ref) => {
  const {
    name,
    label,
    tooltip,
    options,
    formState,
    control,
    isMulti = true,
    fullwidth = false,
    required = true,
  } = props
  const {errors} = formState

  const labelClassName = required
    ? 'col-form-label required fw-bold fs-6'
    : 'col-form-label fw-bold fs-6'

  const labelWidth = fullwidth ? 'd-flex align-items-center' : 'col-lg-4 d-flex align-items-center'
  const inputWidth = fullwidth ? 'fv-row' : 'col-lg-8 fv-row'

  const renderProps: {
    getOptionLabel?: (option: any) => string
    getOptionValue?: (option: any) => string
  } = {}

  if (props.renderOptionLabel) {
    renderProps.getOptionLabel = props.renderOptionLabel
    renderProps.getOptionValue = getOptionValue
  }

  return (
    <>
      <div className={labelWidth}>
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
        {tooltip && <TooltipInfo text={tooltip} />}
      </div>

      <div className={inputWidth}>
        <Controller
          control={control}
          name={name}
          render={({field: {onChange, onBlur, value, name, ref}}) => (
            <Select
              options={options}
              isLoading={formState.isLoading}
              onChange={onChange}
              isMulti={isMulti}
              onBlur={onBlur}
              value={value} //value.filter((v: any) => v.value !== '')
              name={name}
              styles={customStyles}
              ref={ref}
              {...renderProps}
            />
          )}
        />
        {getNestedProperty(errors, name)?.message ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              {getNestedProperty(errors, name)?.message.toString()}
            </div>
          </div>
        ) : null}
      </div>
    </>
  )
})

export default ReactSelect
