import {IFile} from '../schemas'

const startUpload = async (UppyInstance: any): Promise<IFile[]> => {
  const result = await UppyInstance.upload()

  if (result.failed.length > 0) {
    throw new Error('Error occurred during file upload')
  }

  if (result.successful.length > 0) {
    // Set the uploaded file URLs in the form state
    return formattedFileArray(result.successful)
  } else {
    return formattedFileArray(UppyInstance.getFiles())
  }
}

function formattedFileArray(data: any): IFile[] {
  const files = data.map((file: any) => ({
    filename: file.name,
    filenameSaved: getFilenameFromURL(file.uploadURL),
    fileURL: file.uploadURL,
    fileSizeInBytes: file.size,
    fileSize: formatFileSize(file.size),
  }))

  return files
}

function formatFileSize(bytes: number): string {
  if (bytes === 0) {
    return '0 B'
  }

  const k = 1024
  const sizes: string[] = ['B', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

function getFilenameFromURL(url: string): string {
  const urlParts = url.split('/')
  const filenameWithParams = urlParts[urlParts.length - 1]
  const filename = filenameWithParams.split('?')[0]
  return filename
}

export {startUpload, formatFileSize}
