import React, {FC} from 'react'
import {Placeholder} from 'react-bootstrap'

type Props = {
  headers: any
  skeletonCount: number
}

export const SkeletonTable = ({headers}: any) => {
  return (
    <tr>
      {headers.map((column: any) => (
        <td key={column.id} className={column.id === 'actions' ? 'text-end' : ''}>
          {column.id === 'selection' ? (
            <Placeholder as='p' animation='glow'>
              <Placeholder xs={8} size='lg' />
            </Placeholder>
          ) : column.id === 'actions' ? (
            <Placeholder as='p' animation='glow'>
              <Placeholder xs={4} size='lg' />
            </Placeholder>
          ) : (
            <Placeholder as='p' animation='glow'>
              <Placeholder xs={10} size='lg' />
            </Placeholder>
          )}
        </td>
      ))}
    </tr>
  )
}

export const SkeletonTableLoading: FC<Props> = React.memo(({headers, skeletonCount = 10}) => {
  return (
    <>
      {Array.from({length: skeletonCount}, (_, i) => (
        <SkeletonTable headers={headers} key={i} />
      ))}
    </>
  )
})
