import {Dispatch, SetStateAction} from 'react'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  limit: number
  totalPages?: number
  totalResults?: number
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
  entity?: string
  current_status?: string
  stage?: string
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  data?: T
  status?: string
  message?: string
  pagination: PaginationState
}

export interface ResponsePaginated<T> {
  data: Data<T>
  message: string
  status: string
}

export interface Data<T> {
  results: T
  pagination: PaginationState
}

export interface SelectList {
  value: string
  label: string
}

export type SelectListResponse = Response<Array<SelectList>>

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}

export type IUnregisteredUsers = {
  name: string
  email: string
  registration_url: string
}
