import {useState, useEffect} from 'react'
import {DragDrop, StatusBar} from '@uppy/react'
import uppy from './UppyInstance'
import FileItem from './FileItem'

import '@uppy/core/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/status-bar/dist/style.css'
import './dropzone.css'

const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const removeFile = (file) => {
    uppy.removeFile(file.id)
    setSelectedFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id))
  }

  useEffect(() => {
    const handleFilesAdded = () => {
      setSelectedFiles(uppy.getFiles())
    }

    const handleProgress = () => {
      setSelectedFiles(uppy.getFiles())
    }

    const handleUploadSuccess = (file, response) => {
      console.log('upload-success:', file)
    }

    const handleComplete = (result) => {
      setSelectedFiles(uppy.getFiles())
      console.log('result:', result)
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
    }

    const handleUploadError = (file, error, response) => {
      setSelectedFiles(uppy.getFiles())
      console.log('error with file:', file.id)
      console.log('error message:', error)
    }

    setSelectedFiles(uppy.getFiles())
    uppy.on('files-added', handleFilesAdded)
    uppy.on('progress', handleProgress)
    uppy.on('upload-success', handleUploadSuccess)
    uppy.on('complete', handleComplete)
    uppy.on('upload-error', handleUploadError)

    return () => {
      uppy.cancelAll()
      uppy.off('files-added', handleFilesAdded)
      uppy.off('progress', handleProgress)
      uppy.off('upload-success', handleUploadSuccess)
      uppy.off('complete', handleComplete)
      uppy.off('upload-error', handleUploadError)
    }
  }, [])

  return (
    <>
      <label className='col-form-label fw-bold fs-6'>Documents</label>
      <DragDrop
        // width='100%'
        // height='150px'
        uppy={uppy}
      />
      <StatusBar uppy={uppy} hideUploadButton hideAfterFinish showProgressDetails />
      {/* <Button variant='primary' className='text-nowrap text-white ms-4' onClick={startUpload}>
        Start Upload
      </Button> */}

      {selectedFiles.length > 0 && (
        <div className='row'>
          <div className='col-md-12'>
            <div className='dropzone dropzone-queue mb-2'>
              <div className='dropzone-items wm-200px'>
                {selectedFiles.map((file) => (
                  <FileItem file={file} removeFile={removeFile} key={file.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FileUpload
