import {z} from 'zod'
import {enumError, validationRules} from './validationRules'
import {Prisma} from '@prisma/models'
import {CaseStageStatusKeys, CaseStatusKeys} from './enums'
import {fileSchema} from './fileSchema'

export const jurisdictionDetailsSchema = z
  .object({
    notice_issuing_department: z.string().max(100).nullable(),
    officer_name: z.string().max(100).nullable(),
    officer_designation: z.string().max(50).nullable(),
    officer_email: z.string().max(50).nullable(), //validationRules.emailOptional,
    officer_phone: z.string().max(15).nullable(),
    notice_issue_address: z.string().max(500).nullable(),
    response_address: z.string().max(500).nullable(),
  })
  .optional()

export const caseStageSchema = z
  .object({
    ////////////////**************** DUPLICATE IN caseImportSchema.ts ****************////////////////
    caseId: validationRules.objectId,
    stage: z.nativeEnum(CaseStageStatusKeys, enumError),
    nature_of_dispute: validationRules.safeTextareaOptional,
    tax_in_dispute: validationRules.amountOptional,
    case_reference: z.string().max(100).nonempty(),
    date_of_notice: validationRules.date,
    date_of_receipt: validationRules.date,
    due_date_of_reply: validationRules.date,
    current_status: z.nativeEnum(CaseStatusKeys, enumError),
    period_from: validationRules.date,
    period_to: validationRules.date,
    files: z.array(fileSchema).optional(),
    jurisdiction_details: jurisdictionDetailsSchema,
  })
  .refine((data) => data.period_to >= data.period_from, {
    message: 'Period To must be greater than or equal to Period From',
    path: ['period_to'],
  })
  .refine((data) => data.period_to <= data.date_of_notice, {
    message: 'Period To must be less than or equal to Date of Notice',
    path: ['period_to'],
  })
  .refine((data) => data.date_of_receipt >= data.date_of_notice, {
    message: 'Date of Receipt must be greater than or equal to Date of Notice',
    path: ['date_of_receipt'],
  })
  .refine((data) => data.due_date_of_reply > data.date_of_receipt, {
    message: 'Due Date of Reply must be greater than Date of Receipt',
    path: ['due_date_of_reply'],
  })

export type ICaseStage = Prisma.CaseStageGetPayload<{
  include: {
    sub_stages: true
  }
}>

export type ICaseStageUpdate = Omit<Prisma.CaseUpdateInput, 'case_no' | 'caseStages'>
export type ICaseStageForm = z.infer<typeof caseStageSchema>
export type IJurisdictionDetails = z.infer<typeof jurisdictionDetailsSchema>
