import {z} from 'zod'
import {validationRules} from './validationRules'
import {Response} from './models'
import {Prisma} from '@prisma/models'

export const teamSchema = z
  .object({
    name: validationRules.safeString,
    users: validationRules.reactSelectID,
  })
  .strict()

export const teamIdsSchema = z
  .object({
    teamIds: z.array(validationRules.objectId.nonempty()),
  })
  .strict()

export type ITeam = Prisma.TeamGetPayload<{
  include: {
    users: true
  }
}>

export type ITeamForm = z.infer<typeof teamSchema>
export type ITeamFormBackend = z.infer<typeof teamSchema>
export type ITeamIdsForm = z.infer<typeof teamIdsSchema>
export type TeamsQueryResponse = Response<Array<ITeam>>
