import {getDownloadUrl} from '@/_custom/core'
import {IFile} from '@/_custom/schemas'
import React, {useState} from 'react'

interface Props {
  path: string
  caseId: string
  file: IFile
}

const DownloadButton: React.FC<Props> = ({path, caseId, file}) => {
  const [loading, setLoading] = useState(false)

  const handleDownload = async () => {
    setLoading(true)
    try {
      const url = `${path}/${file.filename}`
      const postData = {id: caseId, filename: file.filename, filenameSaved: file.filenameSaved}
      const {data} = await getDownloadUrl(url, postData)
      //GOOD-TO-HAVE use react query to enable caching here.
      const presignedURL = data?.fileURL
      const link = document.createElement('a')
      link.href = presignedURL || ''
      link.setAttribute('download', file.filename || '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      setLoading(false)
    } catch (error) {
      console.error('Error downloading file:', error)
      setLoading(false)
    }
  }

  return (
    <button
      type='button'
      className='btn btn-sm btn-light'
      key={file.filenameSaved}
      onClick={handleDownload}
      disabled={loading}
    >
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          <i className='ki-duotone ki-paper-clip'></i>
          {`${file.filename} (${file.fileSize})`}
        </>
      )}
    </button>
  )
}

export default DownloadButton
