import React, {forwardRef, InputHTMLAttributes, Ref} from 'react'
import {TooltipInfo} from '../ui'
import {FormState, FieldValues, Controller, Control} from 'react-hook-form'
import {getNestedProperty} from '../../helpers'
import {NumericFormat} from 'react-number-format'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label: string
  type?: string
  tooltip?: string
  required?: boolean
  formState: FormState<FieldValues>
  control: Control<any>
  fullwidth?: boolean
}

const InputAmount = forwardRef(
  (
    {
      name,
      label,
      formState,
      tooltip,
      control,
      type = 'text',
      required = true,
      fullwidth = false,
      ...rest
    }: Props,
    ref: Ref<HTMLInputElement>
  ) => {
    const {errors, isSubmitting} = formState
    const labelClassName = required
      ? 'col-form-label required fw-bold fs-6'
      : 'col-form-label fw-bold fs-6'
    const labelWidth = fullwidth
      ? 'd-flex align-items-center'
      : 'col-lg-4 d-flex align-items-center'
    const inputWidth = fullwidth ? 'fv-row' : 'col-lg-8 fv-row'

    return (
      <>
        <div className={labelWidth}>
          <label htmlFor={name} className={labelClassName}>
            {label}
          </label>
          {tooltip && <TooltipInfo text={tooltip} />}
        </div>

        <div className={inputWidth}>
          <Controller
            control={control}
            name={name}
            defaultValue=''
            render={({field: {onChange, ref, name, value}}) => (
              <NumericFormat
                className='form-control form-control-lg form-control-solid'
                type='text'
                thousandsGroupStyle='lakh'
                thousandSeparator=','
                prefix={'₹ '}
                value={value}
                disabled={isSubmitting}
                onValueChange={(v) => {
                  onChange(Number(v.value))
                }}
              />
            )}
          />

          {getNestedProperty(errors, name)?.message ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {getNestedProperty(errors, name)?.message.toString()}
              </div>
            </div>
          ) : null}
        </div>
      </>
    )
  }
)

export default InputAmount
