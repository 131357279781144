import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {useAuth} from '@/app/modules/auth'
import {rbac, Rule} from '@/_custom/schemas'
import {useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'

export function MenuInner() {
  const {currentUser} = useAuth()
  const {pathname, search} = useLocation()
  const [casesActive, setCasesActive] = useState(false)
  const [openCasesActive, setOpenCasesActive] = useState(false)
  const [closedCasesActive, setClosedCasesActive] = useState(false)

  useEffect(() => {
    if (pathname === '/cases') {
      const searchParams = new URLSearchParams(search)
      const currentStatus = searchParams.get('current_status')
      if (currentStatus && currentStatus !== 'closed') {
        setCasesActive(false)
        setOpenCasesActive(true)
        setClosedCasesActive(false)
      } else if (currentStatus === 'closed') {
        setCasesActive(false)
        setOpenCasesActive(false)
        setClosedCasesActive(true)
      } else {
        setCasesActive(true)
        setOpenCasesActive(false)
        setClosedCasesActive(false)
      }
    } else {
      setCasesActive(false)
      setOpenCasesActive(false)
      setClosedCasesActive(false)
    }
  }, [pathname, search])

  // const rbacTeamsRead = rbac.can(currentUser?.role, Rule.TEAMS_READ)
  const rbacUsersRead = rbac.can(currentUser?.role, Rule.USERS_READ)
  // const rbacAdminAutoAssignRules = rbac.can(currentUser?.role, Rule.ADMIN_AUTO_ASSIGN_RULES)
  const rbacAdminManageOrganization = rbac.can(currentUser?.role, Rule.ADMIN_MANAGE_ORGANIZATION)

  return (
    <>
      {/* <MenuItem title='Dashboard' to='/dashboard' /> */}
      <MenuInnerWithSub title='Cases' to='' menuPlacement='bottom-start' menuTrigger='click'>
        <MenuItem title='All Cases' to='/cases' isForcedActive={casesActive} />
        <MenuItem
          title='Open Cases'
          to='/cases?current_status=open'
          isForcedActive={openCasesActive}
        />
        <MenuItem
          title='Closed Cases'
          to='/cases?current_status=closed'
          isForcedActive={closedCasesActive}
        />
      </MenuInnerWithSub>

      {rbac.can(currentUser?.role, 'entities:read') && <MenuItem title='Clients' to='/entities' />}

      {(rbacUsersRead || rbacAdminManageOrganization) && (
        <MenuInnerWithSub title='Admin' to='' menuPlacement='bottom-start' menuTrigger='click'>
          {/* {rbacTeamsRead && <MenuItem icon='abstract-26' title='Manage Team' to='/teams' />} */}
          {rbacUsersRead && <MenuItem icon='user' to='/users' title='User management' />}
          {/* {rbacAdminAutoAssignRules && (
            <MenuItem icon='abstract-4' to='/admin/auto-assign-rules' title='Auto Assign Rules' />
          )} */}
          {rbacAdminManageOrganization && (
            <MenuItem
              icon='abstract-4'
              to='/admin/manage-organization'
              title='Manage Organization'
            />
          )}
          {rbacAdminManageOrganization && (
            <MenuItem icon='user' to='/admin/unregistered-users' title='Unregistered Users' />
          )}
        </MenuInnerWithSub>
      )}
    </>
  )
}
