import {KTIcon} from '@/_metronic/helpers'
import Modal, {ModalProps} from 'react-bootstrap/Modal'

export interface ErrorModalProps extends ModalProps {
  header?: string
  message?: string
}

export const ErrorModal = ({
  header = 'Oops...',
  message = 'Something went wrong.',
  ...props
}: ErrorModalProps) => {
  return (
    <Modal {...props} size='sm' aria-labelledby='contained-modal-title-vcenter' centered>
      <div className='bg-light-danger rounded'>
        <Modal.Header className='pb-0' closeButton>
          <Modal.Title as='h5'></Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-0'>
          <div className='d-flex flex-center flex-column '>
            <KTIcon iconName='information-5' className=' text-dark fs-4x mb-5' />
            <div className='text-center'>
              <h5 className='fw-bolder fs-1 mb-5'>{header}</h5>
              <div className='mb-9 fs-5'>{message}</div>
              <div className='d-flex flex-center flex-wrap'>
                <button className='btn btn-danger m-2' onClick={props.onHide}>
                  Ok
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}
