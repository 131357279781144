export enum EntityEnum {
  taxpayer = 'Taxpayer',
  consultant = 'Consultant',
}

export enum TeamType {
  manual = 'manual',
  branch_org = 'branch_org',
  branch_custom = 'branch_custom',
  case = 'case',
}

export enum RoleKeys {
  superadmin = 'superadmin',
  admin = 'admin',
  user = 'user',
  consultant_admin = 'consultant_admin',
  consultant_user = 'consultant_user',
  taxpayer_admin = 'taxpayer_admin',
  taxpayer_user = 'taxpayer_user',
}

export enum Role {
  superadmin = 'Super Admin',
  admin = 'Admin',
  user = 'User',
  consultant_admin = 'HNA Partner',
  consultant_user = 'HNA User',
  taxpayer_admin = 'Taxpayer Admin',
  taxpayer_user = 'Taxpayer User',
}

export enum CaseStatusKeys {
  //*** UPDATE caseSubStageSchemaNormalStages MANUALLY ***
  ResponsePending = 'ResponsePending',
  ResponseSubmitted = 'ResponseSubmitted',
  PHPending = 'PHPending',
  AdjournmentFiled = 'AdjournmentFiled',
  AdditionalPHRequired = 'AdditionalPHRequired',
  PHCompleted = 'PHCompleted',
  OrderPassed = 'OrderPassed',
  Closed = 'Closed',
}

export enum CaseStatus {
  //*** UPDATE caseSubStageSchemaNormalStages MANUALLY ***
  ResponsePending = 'Response Pending',
  ResponseSubmitted = 'Appeal / Response Submitted',
  PHPending = 'PH Pending',
  AdjournmentFiled = 'Adjournment Filed',
  AdditionalPHRequired = 'Additional PH Required',
  PHCompleted = 'PH Completed',
  OrderPassed = 'Order Passed',
  Closed = 'Closed',
}

export enum CaseStageStatusKeys {
  VoluntaryIntimation = 'VoluntaryIntimation',
  InquiryLetter = 'InquiryLetter',
  ASMT_10 = 'ASMT_10',
  Investigation = 'Investigation',
  DepartmentalAudit = 'DepartmentalAudit',
  ADT_02 = 'ADT_02',
  DRC_01A = 'DRC_01A',
  DRC_01 = 'DRC_01',
  AppealAgainstOIO = 'AppealAgainstOIO',
  AppealToTribunal = 'AppealToTribunal',
  AppealToHighCourt = 'AppealToHighCourt',
  AppealToSupremeCourt = 'AppealToSupremeCourt',
  AAR = 'AAR',
  AAAR = 'AAAR',
  Opinion = 'Opinion',
  Others = 'Others',
}

export enum CaseStageStatus {
  VoluntaryIntimation = 'Voluntary Intimation',
  InquiryLetter = 'Inquiry Letter',
  ASMT_10 = 'ASMT-10 (Scrutiny)',
  Investigation = 'Investigation',
  DepartmentalAudit = 'Departmental Audit',
  ADT_02 = 'ADT-02 (Audit Report)',
  DRC_01A = 'DRC-01A (Pre-SCN)',
  DRC_01 = 'DRC-01 (SCN)',
  AppealAgainstOIO = 'Appeal Against OIO',
  AppealToTribunal = 'Appeal To Tribunal',
  AppealToHighCourt = 'Appeal To High Court',
  AppealToSupremeCourt = 'Appeal To Supreme Court',
  AAR = 'AAR',
  AAAR = 'AAAR',
  Opinion = 'Opinion',
  Others = 'Others',
}
