import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {zodResolver} from '@hookform/resolvers/zod'
import {IForgotPasswordForm, forgotPasswordSchema} from '@/_custom/schemas'
import {Input, Submit, showErrorModal} from '@/_custom/components'
import {getErrorMessage} from '@/_custom/helpers'
import {requestPassword} from '../core/_requests'

const ForgotPassword = () => {
  const [passwordRequestSent, setPasswordRequestSent] = useState(false)

  const {register, handleSubmit, formState} = useForm<IForgotPasswordForm>({
    mode: 'onChange',
    resolver: zodResolver(forgotPasswordSchema),
  })

  const onSubmit = async (values: IForgotPasswordForm) => {
    try {
      await requestPassword(values.email)
      setPasswordRequestSent(true)
    } catch (error) {
      const errorMsg = getErrorMessage(error)
      showErrorModal({message: errorMsg})
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            Enter your email to reset your password.
          </div>
          {/* end::Link */}
        </div>
        {/* end::Heading */}

        {passwordRequestSent ? (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>
              A confirmation email has been successfully sent to reset your password. Please ensure
              that the email address you provided is associated with an active account.
            </div>
          </div>
        ) : (
          <div>
            <div className='row mb-8'>
              <Input
                {...register('email')}
                label='Email'
                formState={formState}
                required={false}
                fullwidth={true}
                inputClassName='form-control bg-transparent'
              />
            </div>

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <Submit isSubmitting={formState.isSubmitting} className='btn btn-primary me-4' />
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-light'
                  disabled={formState.isSubmitting}
                >
                  Cancel
                </button>
              </Link>
            </div>
            {/* end::Form group */}
          </div>
        )}
      </>
    </form>
  )
}

export default ForgotPassword
