import React from 'react'

const EmptySearchPlaceholder = () => {
  return (
    <div className='card-title'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative my-1'></div>
      {/* end::Search */}
    </div>
  )
}

export default EmptySearchPlaceholder
