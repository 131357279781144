import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as authHelper from './AuthHelpers'
import {getUserByToken, logoutCognito} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {EntityEnum, IUser, RoleKeys} from '@/_custom/schemas'
import {IAuthModel} from '@/_custom/schemas/authSchema'

type AuthContextProps = {
  auth: IAuthModel | undefined
  saveAuth: (auth: IAuthModel | undefined) => void
  currentUser: IUser | undefined
  currentUserEntityType: EntityEnum | undefined
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  currentUserEntityType: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<IAuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()
  const [currentUserEntityType, setCurrentUserEntityType] = useState<EntityEnum | undefined>(
    undefined
  )
  const saveAuth = (auth: IAuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  useEffect(() => {
    switch (currentUser?.role) {
      case RoleKeys.superadmin:
      case RoleKeys.consultant_admin:
      case RoleKeys.consultant_user:
        setCurrentUserEntityType(EntityEnum.consultant)
        break

      case RoleKeys.taxpayer_admin:
      case RoleKeys.taxpayer_user:
        setCurrentUserEntityType(EntityEnum.taxpayer)
        break

      default:
        setCurrentUserEntityType(undefined)
        break
    }
  }, [currentUser])

  const logout = async () => {
    logoutCognito(auth?.tokens.AccessToken as string)
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{auth, saveAuth, currentUser, setCurrentUser, currentUserEntityType, logout}}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth?.tokens?.AccessToken) {
      requestUser(auth.tokens.AccessToken)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
