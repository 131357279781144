import RBAC from 'complete-rbac'
import {Rule} from './rbac_enum'
import {RoleKeys} from '../enums'

const rbac_rules = {
  [RoleKeys.user]: {
    can: [Rule.NA],
  },
  [RoleKeys.taxpayer_user]: {
    can: [Rule.CASE_READ],
  },
  [RoleKeys.taxpayer_admin]: {
    can: [Rule.ADMIN_MANAGE_ORGANIZATION, Rule.CASE_READ, Rule.TEAMS_ALL, Rule.USERS_ALL],
  },
  [RoleKeys.consultant_user]: {
    can: [
      Rule.CASE_CREATE,
      Rule.CASE_READ,
      Rule.CASE_UPDATE,
      Rule.CASE_NOTE_CREATE,
      Rule.CASE_NOTE_READ,
    ],
  },
  [RoleKeys.consultant_admin]: {
    can: [
      Rule.ADMIN_MANAGE_ORGANIZATION,
      Rule.CASE_ALL,
      Rule.CASE_TEAMS_ASSIGN,
      Rule.CASE_NOTE_CREATE,
      Rule.CASE_NOTE_READ,
      Rule.TEAMS_ALL,
      Rule.USERS_ALL,
      Rule.ENTITIES_ALL,
    ],
  },
  [RoleKeys.admin]: {
    can: [Rule.ADMIN_ALL],
    inherits: [RoleKeys.consultant_admin],
  },
  [RoleKeys.superadmin]: {
    // can: ['case:read'],
    can: ['*'],
  },
}

const rbac = new RBAC(rbac_rules)
export {rbac_rules, rbac}
