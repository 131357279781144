import React, {useState, useRef} from 'react'
import {Button, Overlay, Popover, Spinner} from 'react-bootstrap'

interface ConfirmAlertProps {
  message: string
  onConfirm: () => void
  children: React.ReactChild
}

const ConfirmAlert: React.FC<ConfirmAlertProps> = ({message, onConfirm, children}) => {
  const [showPopover, setShowPopover] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const handleConfirm = async () => {
    setIsLoading(true)

    try {
      await onConfirm()
    } catch (error) {
      // Handle any error that occurred during deletion
      console.error(error)
    } finally {
      setIsLoading(false)
      setShowPopover(false)
    }
  }

  const popover = (
    <Popover id='confirm-popover'>
      <Popover.Body>
        <p>{message}</p>
        <Button
          variant='primary'
          size='sm'
          onClick={handleConfirm}
          style={{marginRight: '0.5rem'}}
          disabled={isLoading}
        >
          {isLoading ? <Spinner animation='border' size='sm' /> : 'Confirm'}
        </Button>
        <Button variant='secondary' size='sm' onClick={() => setShowPopover(false)}>
          Cancel
        </Button>
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <div ref={buttonRef} onClick={() => setShowPopover(true)}>
        {children}
      </div>

      <Overlay
        show={showPopover}
        placement='bottom'
        target={buttonRef.current}
        onHide={() => setShowPopover(false)}
        transition={false}
      >
        {popover}
      </Overlay>
    </>
  )
}

export default ConfirmAlert
