import {useQuery} from '@tanstack/react-query'
import {teamKeys, userKeys} from '../helpers/query_helper'
import {getTeamsForSelect, getUsersForSelect} from './request'

const QueryTeamsForSelect = () => {
  const queryData = useQuery({
    queryKey: teamKeys.detail('TeamsForSelect'),
    queryFn: () => {
      return getTeamsForSelect()
    },
    cacheTime: 300000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60000,
  })

  return queryData
}

const QueryUsersForSelect = () => {
  const queryData = useQuery({
    queryKey: userKeys.detail('UsersForSelect'),
    queryFn: () => {
      return getUsersForSelect()
    },
    cacheTime: 300000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 60000,
  })

  return queryData
}

// const QueryDownloadFile = (url: string, postData: IDownloadUrlForm) => {
//   const queryData = useQuery({
//     queryKey: adminKeys.detail(postData.filenameSaved),
//     queryFn: () => {
//       return getDownloadUrl(url, postData)
//     },
//     cacheTime: 300000,
//     keepPreviousData: true,
//     refetchOnWindowFocus: false,
//     staleTime: 300000,
//   })

//   return queryData
// }

export {
  QueryTeamsForSelect,
  QueryUsersForSelect,
  // QueryDownloadFile
}
