import {dashboardKeys} from '@/_custom/helpers'
import {useQuery} from '@tanstack/react-query'
import axios, {AxiosResponse} from 'axios'
import {IOpenCloseCount} from './dashboard.model'
import {Response} from '@/_metronic/helpers'
import {ICase} from '@/_custom/schemas'

const API_URL = process.env.REACT_APP_API_URL
const DASHBOARD_URL = `${API_URL}/dashboard`

export const QueryOpenCloseCount = () => {
  const queryData = useQuery({
    queryKey: dashboardKeys.detail('OpenCloseCount'),
    queryFn: () => {
      return axios
        .get(`${DASHBOARD_URL}/get-open-close-count`)
        .then((d: AxiosResponse<Response<IOpenCloseCount>>) => d.data)
    },
    cacheTime: 600000, //10mins
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 300000, //5mins
  })

  return queryData
}

export const QueryCasesCountByStage = () => {
  const queryData = useQuery({
    queryKey: dashboardKeys.detail('CasesCountByStage'),
    queryFn: () => {
      return axios
        .get(`${DASHBOARD_URL}/get-cases-count-by-stage`)
        .then((d: AxiosResponse<Response<ICase[]>>) => d.data)
    },
    cacheTime: 600000, //10mins
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 300000, //5mins
  })

  return queryData
}

export const QueryOverdueCases = () => {
  const queryData = useQuery({
    queryKey: dashboardKeys.detail('QueryOverDueCases'),
    queryFn: () => {
      return axios
        .get(`${DASHBOARD_URL}/get-overdue-cases`)
        .then((d: AxiosResponse<Response<ICase[]>>) => d.data)
    },
    cacheTime: 600000, //10mins
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 300000, //5mins
  })

  return queryData
}

export const QueryUpcomingCases = () => {
  const queryData = useQuery({
    queryKey: dashboardKeys.detail('QueryUpcomingCases'),
    queryFn: () => {
      return axios
        .get(`${DASHBOARD_URL}/get-upcoming-cases`)
        .then((d: AxiosResponse<Response<ICase[]>>) => d.data)
    },
    cacheTime: 600000, //10mins
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 300000, //5mins
  })

  return queryData
}
