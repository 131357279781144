const PasswordMeterBar = () => {
  return (
    <>
      {/* begin::Meter */}
      <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
      </div>
      {/* end::Meter */}
      <div className='text-muted'>
        Use 6 or more characters with a mix of letters, numbers & symbols.
      </div>
    </>
  )
}

export default PasswordMeterBar
