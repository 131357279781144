import axios, {AxiosResponse} from 'axios'
import {ID, IUser} from '@/_custom/schemas'
import {IAuthModel, IChangePasswordForm, ITokens} from '@/_custom/schemas/authSchema'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh-tokens`
export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const CHANGE_PASSWORD_URL = `${API_URL}/auth/change-password`

// Server should return IAuthModel
export async function login(email: string, password: string, token: string) {
  if (!token) {
    throw new Error('User Validation Failed. Please reload the page and retry.')
  }
  const response = await axios.post<AxiosResponse<IAuthModel>>(LOGIN_URL, {
    email,
    password,
    token,
  })

  return response.data
}

// Server should return IAuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return IAuthModel
export async function changePassword(
  id: ID,
  token: string | undefined,
  values: IChangePasswordForm
): Promise<IUser | undefined> {
  return axios
    .post(`${CHANGE_PASSWORD_URL}/${id}/${token}`, values)
    .then((response: AxiosResponse<Response>) => response.data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserByToken(token: string) {
  // BUG verify token getting called continuously
  const response = await axios.post<AxiosResponse<IUser>>(GET_USER_BY_ACCESSTOKEN_URL, {
    accessToken: token,
  })

  return response.data
}

export async function refreshToken(token: string) {
  const response = await axios.post<ITokens>(REFRESH_TOKEN_URL, {
    refreshToken: token,
  })

  return response.data
}

export async function logoutCognito(token: string) {
  if (!token) {
    return
  }
  const response = await axios.post<AxiosResponse<IUser>>(LOGOUT_URL, {
    accessToken: token,
  })

  return response.data
}
