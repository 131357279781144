import DownloadButton from './DownloadButton'
import {IFile} from '@/_custom/schemas'

type Props = {
  caseId: string
  files?: IFile[]
  path: string
}

const FilesDownloadList = ({caseId, files, path}: Props) => {
  return (
    <>
      {files && files.length > 0 && (
        <div className='py-5'>
          <div className='d-flex align-items-center flex-wrap d-grid gap-2'>
            {files.map((file: IFile) => (
              <DownloadButton caseId={caseId} file={file} key={file.filenameSaved} path={path} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default FilesDownloadList
