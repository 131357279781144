import React from 'react'
import {Table} from 'react-bootstrap'

interface DataTableProps {
  data: any[] // Use any[] for generic JSON data
  columnWidths?: {[key: string]: string}
  indexColumn?: boolean
}

const JsonToTable: React.FC<DataTableProps> = ({data, columnWidths, indexColumn = false}) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>
  }

  const headers: string[] = Object.keys(data[0])
  const columnConfig = headers.map((header) => ({
    header,
    width: columnWidths?.[header],
  }))

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          {indexColumn && <th>Sr</th>}
          {columnConfig.map((config) => (
            <th key={config.header} style={{width: config.width}}>
              {config.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {indexColumn && <td>{index + 1}</td>}
            {columnConfig.map((config) => (
              <td key={config.header} style={{width: config.width}}>
                {row[config.header]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default JsonToTable
