import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '@/_metronic/helpers'
import {
  IUser,
  IUserRegistrationForm,
  IUserUpdate,
  UsersQueryResponse,
} from '@/_custom/schemas/userSchema'
import {USER_URL} from '@/_custom/core'

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<IUser | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}
const createUser = (user: IUser): Promise<IUser | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const updateUser = (user: IUserUpdate, id: ID): Promise<IUser | undefined> => {
  return axios
    .put(`${USER_URL}/${id}`, user)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const deleteUser = (userId: ID): Promise<Response<undefined>> => {
  return axios
    .delete(`${USER_URL}/${userId}`)
    .then((response: AxiosResponse<Response<undefined>>) => response.data)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getUserForRegistration = (id: ID, token: string | undefined): Promise<IUser | undefined> => {
  return axios
    .get(`${USER_URL}/${id}/${token}`)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

const registerByInvite = (
  id: ID,
  token: string | undefined,
  user: IUserRegistrationForm
): Promise<IUser | undefined> => {
  return axios
    .post(`${USER_URL}/${id}/${token}`, user)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}

export {
  getUsers,
  getUserById,
  deleteUser,
  deleteSelectedUsers,
  createUser,
  updateUser,
  getUserForRegistration,
  registerByInvite,
}
